@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
@import url("../css/bootstrap.min.css");
@import url("../fonts/fonts.css");

/* css for all  */
:root {
  --ac-secondary-black: #0F0F0F;
  --secondary-color: #878787;
  --ac-gray: #F8F8F8;
  --ac-primary-green: #4ACC86;
  --secondary-red: #F06341;
  --ac-primary-blue: #1438F5;
  --white: #ffffff;
  --black: #000000;
  --gold: #8E810F;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background-color: grey !important;
  box-shadow:none !important;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
body {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
  font-family: 'Helvetica Neue';
}
.graph-data-insights .dropdown .dropdown-toggle svg{
  width: 30px !important;
}
.dropdown-tab-content .dropdown .dropdown-toggle:active{
  border: 1px solid #1438F5 !important;
}
img {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  font-family: 'Helvetica Neue';
}

a {
  color: var(--ac-primary-blue);
}

a,
a:hover,
a:focus {
  text-decoration: none;
}

button,
button:focus,
button:hover {
  outline: none;
  box-shadow: none;
  cursor: pointer;
  position: relative;
}

input {
  outline: none;
}

input::placeholder {
  color: var(--secondary-color);
  font-size: 14px;
  line-height: 17px;
}

input:-moz-placeholder {
  color: var(--secondary-color);
  font-size: 14px;
  line-height: 17px;
}

input:-ms-input-placeholder {
  color: var(--secondary-color);
  font-size: 14px;
  line-height: 17px;
}

.row {
  margin: 0;
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, 
.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus, 
.log-in-details .was-validated .form-control:invalid:focus, .log-in-details .form-control.is-invalid:focus, 
.log-in-details .form-control.is-invalid:focus, .log-in-details .was-validated .form-control:invalid:focus {
  box-shadow: none;
}


@media screen and (max-width: 767px) {
  input {
    margin-bottom: 15px;
  }

  .error-msg {
    bottom: -8px;
  }
}


/* form css */
.error-msg {
  color: var(--secondary-red);
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

input,
.form-control {
  background: #FFFFFF;
  border: 1px solid rgba(188, 188, 188, 0.5);
  border-radius: 1px;
  color: var(--ac-secondary-black);
  margin-bottom: 24px;
  padding: 10px 15px;
  max-width: 430px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  height: 48px;
}

input[type="password"] {
  padding: 10px 35px 10px 15px;
}

input:focus,
.form-control:focus, 
input.form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--ac-primary-green);
}

.form-label {
  font-family: 'Helvetica Neue';
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 12px;
  display: flex;
  color:var(--ac-secondary-black)
}

button {
  border-radius: 1px;
  border: none;
  width: 100%;
  padding: 8.5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  transition: all ease-in-out 0.3s;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button img,
button svg {
  margin-right: 16px;
}

.disabled {
  pointer-events: none !important;
  background: rgba(188, 188, 188, 0.5) !important;
}

select,
.form-select {
  background: #FFFFFF;
  border: 1px solid rgba(188, 188, 188, 0.5);
  border-radius: 1px;
  color: var(--ac-secondary-black);
  margin-bottom: 24px;
  padding: 10px 25px 10px 15px;
  max-width: 430px;
  width: 100%;
  background-image: url(../images/down-arrow.svg);
  background-repeat: no-repeat;
  background-position: 96% center;
  background-size: 10px 10px;
  appearance: none;
  height: 48px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

select:focus,
.form-select:focus {
  outline: none;
  box-shadow: none;
  border-color: var(--ac-primary-green);
}

/* form css *

/* -------------------- login page css start -------------------- */

.common-box {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100vh;
}

.login-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  background: #fff;
  z-index: 1;
  padding: 20px 30px;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  color: #0F0F0F;
}

.login-logo a {
  width: 70px;
  display: inline-block;
}

.left-content {
  flex: 2 50%;
  height: 100%;
  overflow: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
}

.form-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
}

.log-in-details {
  max-width: 460px;
  margin: auto;
  padding: 0 15px;
  width: 100%;
  position: relative;
}

.left-content form {
  position: relative;
  margin-bottom: 60px;
}

.left-content form.login-details input {
  margin-bottom: 46px;
}

.left-content form.login-details input:focus {
  border-color: var(--ac-primary-green);
  box-shadow: none;
}

.left-content h3 {
  font-weight: 500;
  font-size: 40px;
  line-height: 49px;
  color: var(--ac-secondary-black);
  margin-bottom: 24px;
}

.left-content .welcome-text {
  color: var(--secondary-color);
  margin-bottom: 40px;
}

.left-content .forgot-password {
  display: flex;
  position: absolute;
  bottom: -26px;
  right: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.left-content .forgot-password:hover,  
.left-content a:focus, .left-content .sign-up a:focus {
  color: var(--ac-primary-green);
}

.left-content .button-with-bg {
  background: var(--ac-primary-green);
  color: var(--white);
  border: none;
  border-radius: 1px;
}

/* .left-content .button-with-bg:hover, 
.left-content .button-with-bg:focus {
  background: var(--ac-secondary-black);
  color: var(--white);
} */

.left-content .button-without-bg {
  background: var(--white);
  box-shadow: inset 0px 0px 0 1px rgba(188, 188, 188, 0.5);
  border-radius: 1px;
}

.left-content .button-without-bg:hover {
  box-shadow: inset 0px 0px 0 2px var(--ac-primary-green);
  color: var(--ac-primary-green);
}

.left-content .sign-up {
  margin: 15px 0 0;
  display: inline-block;
  width: 100%;
}

.left-content .sign-up p {
  color: var(--secondary-color);
}

.left-content .sign-up a {
  color: var(--ac-primary-blue);
}

.left-content .sign-up a:hover {
  color: var(--ac-primary-green);
}

.right-content {
  flex: 2 50%;
  background: var(--ac-gray);
  overflow: hidden;
  height: 100%;
  align-items: center;
  display: flex;
  padding-left: 150px;
}

.right-content img {
  display: flex;
  margin: 0 0 0 auto;
  filter: drop-shadow(40px 40px 80px rgba(0, 0, 0, 0.15));
  max-width: inherit;
  width: inherit;
  /* width: 785px; */
  height: 75vh;
}

@media screen and (min-width: 1600px) {
  .right-content img {
    width: inherit;
    height: 75vh;
  }
}

@media screen and (max-width: 1366px) {
  .left-content form {
    margin-bottom: 50px;
  }

  .left-content form.login-details input {
    margin-bottom: 30px;
  }

  .left-content h3 {
    margin-bottom: 10px;
  }

  .stepFive h3 {
    margin-bottom: 40px;
  }

  .progress-bar {
    margin: 0 0 30px;
  }

  .log-in-details input {
    padding: 10px;
  }
}

@media screen and (max-width: 1280px) {
  .right-content {
    padding-left: 100px;
  }
}

@media screen and (max-width: 1023px) {
  .login-logo {
    top: 10px;
    left: 20px;
    width: 70px;
    padding: 0;
  }
}

@media screen and (max-width: 991px) {
  .log-in-details {
    padding: 0 20px;
  }

  .left-content form.login-details input {
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 767px) {
  .left-content form.login-details input {
    margin-bottom: 25px;
  }

  .left-content {
    width: 100%;
    flex: 1 100%;
    padding-bottom: 20px;
    height: auto;
    overflow: inherit;
  }

  .left-content h3 {
    font-size: 30px;
    line-height: 39px;
    margin-bottom: 10px;
  }

  .left-content .welcome-text {
    margin-bottom: 20px;
  }

  .left-content form {
    margin-bottom: 40px;
  }

  .login-logo {
    position: initial;
    width: 100%;
    padding: 20px;
  }

  .common-box {
    flex-direction: column;
    height: 100%;
  }

  .right-content {
    display: none;
    width: 100%;
    flex: 1 100%;
  }

  .right-content img {
    margin: 50px 0 50px auto;
  }

  .ca-stepFour .eye-off {
    top: 56%;
  }

  .ca-stepFour .password-note {
    bottom: -4px;
  }

  .stepFive h3 {
    margin-bottom: 20px;
  }
}

/* -------------------- login page css end -------------------- */

/* -------------------- reset password page css start -------------------- */

.reset-pass p {
  color: var(--secondary-color);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

/* -------------------- reset password page css end -------------------- */

/* -------------------- progress bar css start -------------------- */
.progress {
  background-color: rgba(188, 188, 188, 0.5);
  ;
  margin: 45px 0 40px;
  height: 4px;
  width: 430px;
  max-width: 100%;
}

.progress-bar {
  background: var(--ac-primary-green);
  height: 4px;
  width: 0;
  transition: width 0.5s ease-in;
}

/* -------------------- progress bar css end -------------------- */

/* -------------------- Create account page css start -------------------- */
form.create-account.ca-stepOne {
  margin-bottom: 48px;
}

.error {
  position: relative;
}

.error-msg {
  color: var(--secondary-red);
  position: absolute;
  bottom: 2px;
  left: 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.error input {
  border-color: var(--secondary-red);
}

.ca-stepFour .form-group {
  position: relative;
}

.ca-stepFour .form-group {
  margin-bottom: 24px;
}

.ca-stepFour .password-note {
  color: var(--secondary-color);
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  position: absolute;
  bottom: 4px;
}

.ca-stepFour .eye-off {
  cursor: pointer;
  position: absolute;
  top: 54%;
  right: 15px;
  transform: translateY(-50%);
}

.stepFive .log-in-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stepFive h3 {
  margin-bottom: 80px;
}

/* -------------------- Create account page css end -------------------- */


/* -------------------- Profile page css start -------------------- */

.acumen-content {
  display: flex;
}
.bg-gray {
  background: var(--ac-gray);  
}
.side-bar-menu {
  width: calc(100% - 83.3%);
  background: var(--ac-gray);
  height: 100vh;
  /* padding: 40px 0; */
  position: fixed;
}

.side-bar-menu .login-logo {
  position: initial;
  margin: 0;
  background: transparent;
  padding: 40px 24px;
  width: 100%;
}
.side-bar-menu .login-logo a {
  width: 118px;
  max-width: 100%;
}

.tab-panel-menu {
  margin: 0;
  padding: 0;
  list-style: none;
  flex-direction: column;
  align-items: flex-start;
}

.tab-panel-menu .nav-item {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.tab-panel-menu .tablinks {
  border-radius: 0;
  justify-content: flex-start;
  background: transparent;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: var(--secondary-color);
  border-left: 2px solid var(--ac-gray);
  padding: 9px 9px 9px 24px;
  text-align: left;
  display: flex;
  margin: 0;
}

.tab-panel-menu .tablinks:hover {
  color: var(--ac-secondary-black);
  border-color: var(--ac-secondary-black);
}


.tab-panel-menu .tablinks.active {
  color: var(--black);
  border-color: var(--ac-secondary-black);
  background-color: transparent;
}

.tab-panel-menu #insights-tab svg path {
  stroke: var(--secondary-color);
}
.tab-panel-menu #insights-tab:hover svg path, 
.tab-panel-menu #insights-tab.active svg path {
  stroke: var(--ac-secondary-black);
}

.tab-panel-menu #settings-tab svg circle, 
.tab-panel-menu #log-out svg path {
  stroke: var(--secondary-color);
}
.tab-panel-menu #settings-tab:hover svg circle, 
.tab-panel-menu #settings-tab.active svg circle, 
.tab-panel-menu #log-out:hover svg path, 
.tab-panel-menu #log-out.active svg path {
  stroke: var(--ac-secondary-black);
}
.tab-panel-menu #settings-tab svg path {
  fill: var(--secondary-color);
}
.tab-panel-menu #settings-tab:hover svg path, 
.tab-panel-menu #settings-tab.active svg path {
  fill: var(--ac-secondary-black);
}

/* Style the tab content */
.tabcontent {
  display: none;
  width: 100%;
}

.right-tab-content {
  width: calc(100% - 224px);
  display: flex;
  margin-left: auto;
}

.top-content-pf {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 56px;
  width: 100%;
}

.search-box {
  display: flex;
  position: relative;
}

.search-box input[type="text"] {
  margin: 0;
  background: var(--ac-gray);
  border-radius: 1px;
  border: none;
  padding: 12px 35px 12px 36px;
  height: 40px;
  width: 317px;
  font-weight: 400;
}

.search-box button {
  position: absolute;
  width: auto;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
  background: transparent;
  padding: 0;
}
.search-box button i {
  color: var(--secondary-color);
}
.pf-notification {
  display: flex;
  align-items: center;
}

.pf-main-dropdown {
  position: relative;
  display: inline-block;
}

.pf-main-dropdown .userImg {
  width: 40px;
  height: 40px;
  margin: 0 25px;
}

.pf-main-dropdown .dropbtn {
  background: transparent;
  margin: 0;
  padding: 0;
}

.pf-main-dropdown .dropbtn.dropdown-toggle::after {
  content: none;
}

.dd-content {
  border: none;
  background-color: var(--white);
  min-width: 160px;
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 10%);
  z-index: 1;
  flex-direction: column;
  padding: 0;
}

.dd-content a {
  color: var(--ac-secondary-black);
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  padding: 18px 24px;
  display: flex;
}

.dd-content a:hover {
  background: var(--ac-gray);
}

.pf-main-dropdown .show::after {
  content: '';
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 15px solid var(--white);
  top: -14px;
  position: absolute;
  right: 20px;
}

.bell-notify {
  cursor: pointer;
  position: relative;
}

.bell-active {
  width: 8px;
  height: 8px;
  background: var(--ac-primary-green);
  display: flex;
  border-radius: 30px;
  border: 1px solid var(--white);
  position: absolute;
  right: 2px;
  top: 1px;
}

.pr-page-title {
  padding: 20px 56px 24px;
  border-bottom: 1px solid rgba(188, 188, 188, 0.5);
}

.pr-page-title h2 {
  font-weight: 400;
  font-size: 54px;
  line-height: 64px;
  color: var(--ac-secondary-black);
  display: flex;
  align-items: center;
  font-family: 'Helvetica Neue Light';
}

.pr-page-title h2 img {
  margin-right: 30px;
}

.update-profile {
  padding: 50px 56px;
}

.edit-user-img {
  border: 1px solid var(--ac-secondary-black);
  border-radius: 100%;
  padding: 3px;
}

.edit-pic {
  width: 120px;
  height: 120px;
  position: relative;
}

.edit-icon {
  position: absolute;
  right: 0;
  bottom: 0;
  box-shadow: 0px 9px 12px rgba(0, 0, 0, 0.15);
  background: var(--white);
  padding: 8px;
  border-radius: 100%;
  cursor: pointer;
}

.other-info h4 {
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: var(--ac-secondary-black);
}

.other-info p {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: var(--secondary-color);
  margin-bottom: 24px;
}

.accounts-btn {
  display: flex;
}

.accounts-btn .acc-btn {
  box-shadow: inset 0px 0px 0 1px rgba(188, 188, 188, 0.5);
  padding: 8px;
  font-size: 12px;
  line-height: 15px;
  margin-right: 12px;
  width: 112px;
  background: var(--white);
  color: var(--ac-secondary-black);
}

.accounts-btn .acc-btn:last-child {
  margin-right: 0;
}

/* .accounts-btn .acc-btn:hover {
  box-shadow: inset 0px 0px 0 2px var(--ac-primary-green);
  color: var(--ac-primary-green);
} */

.profile-form {
  padding: 0 56px;
}

.edit-form {
  display: flex;
  flex-wrap: wrap;
}

.edit-form .form-group {
  width: 430px;
  margin-right: 20px;
  position: relative;
}

.edit-form label {
  color: var(--secondary-color);
}

.input-with-img {
  position: relative;
}

.input-with-img input {
  margin-bottom: 0;
}

.edit-form .eye-off {
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.edit-form .form-group a {
  position: absolute;
  width: auto;
  display: flex;
  right: -125px;
  top: 44px;
  color: var(--ac-secondary-black);
  border-bottom: 1px solid var(--ac-secondary-black);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.edit-form .form-group a:hover {
  border-bottom: 1px solid var(--ac-primary-green);
  color: var(--ac-primary-green);
}

.profile-form .button-with-bg {
  background: rgba(188, 188, 188, 0.5);
  color: var(--white);
  border: none;
  max-width: 205px;
  margin-top: 20px;
  margin: 20px 0 40px;
  font-size: 16px;
  line-height: 30px;
}

.profile-form .button-with-bg:hover {
  background: var(--ac-primary-green);
  color: var(--white);
}

.edit-form .form-group {
  width: 45%;
  margin-right: 25px;
}

.profile-form input,
.profile-form select {
  max-width: 100%;
}

.bill-method span, .bill-method p, .bill-method p a {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.bill-method span {
  color: var(--secondary-color);
}
.bill-method p, .bill-method p a {
  color: var(--black);
}
.bill-method p {
  margin: 12px 0 50px;
}
.bill-method p a {
  display: flex;
  text-decoration: underline;
}

.tab-content {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
/* .tab-content .modal {
  position: absolute;
} */
.update-profile .modal-content {
  box-shadow: 0px 19px 82px rgb(0 0 0 / 10%);
  border-radius: 0;
  border: none;
}
.modal-backdrop.show {
  opacity: 0;
}

.update-profile .modal-dialog {
  max-width: 606px;
}
.update-profile .cancel-close-text {
  display: flex;
  width: 70%;
  justify-content: space-between;
  margin: 51px auto 0;
}
.cancel-close-text button {
  width: auto;
  background: transparent;
  padding: 0;
  margin: 0 ;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--ac-secondary-black);
  position: relative;
}
.cancel-close-text button:after {
  content: '';
  width: 100%;
  height: 1px;
  background-color: var(--black);
  position: absolute;
  bottom: -2px;
}
.cancel-close-text button:hover:after {
  background-color: var(--ac-primary-green);
}
.cancel-close-text button:hover {
  color: var(--ac-primary-green);
  background: transparent;
}

.update-profile .modal-body {
  text-align: center;
  padding: 65px 15px 65px;
}

.tab-content .modal-body p {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--black);
}
.heading-details {
  margin-bottom: 29px;
  padding-left: 40px;
}
.heading-details p {
  color: var(--ac-secondary-black);
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}
.heading-details button {
  background: var(--ac-primary-green);
  color: var(--white);
  margin: 0;
}
.heading-details button:hover {
  background: var(--ac-secondary-black);
}
.manage-users-details .heading-details button {
  max-width: 205px;
  margin-left: auto;
}
.manage-users-details table td:first-child, 
.manage-users-details table th:first-child {
  padding-left: 50px;
}
.manage-users-details table th {
  border-bottom: 2px solid rgba(188, 188, 188, 0.5);
  padding: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.manage-users-details table tr:nth-of-type(even) {
  background: rgba(241, 241, 241, 0.5);
}
.manage-users-details table td {
  border: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 0.75rem;
  color: var(--ac-secondary-black);
}
.add-delete-user {
  display: flex;
  justify-content: space-between;
}
.add-delete-user a {
  color: var(--black);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-decoration: underline;
  margin-left: 10px;
}
.seat-limit {
  padding: 35px 40px 40px;
}
.seat-limit span {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: block;
  margin-bottom: 15px;
  color: var(--ac-secondary-black) !important;
}
.seat-limit a {
  color: var(--black);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  text-decoration: underline;
}
.seat-limit p {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--black);
}
.modal-content {
  box-shadow: 0px 19px 82px rgb(0 0 0 / 10%);
  border-radius: 0;
  border: none;
}
.manage-users-details .modal-dialog {
  max-width: 606px;
}
.manage-users-details .modal-content {
  padding: 65px 88px;
}
.form-button-user button {
  background: transparent;
  border: 0;
  color: var(--black);
  width: auto;
  padding: 0;
  margin: 0 15px;
  position: relative;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
.form-button-user button:after {
  content: '';
  width: 100%;
  height: 1px;
  background-color: var(--black);
  position: absolute;
  bottom: -2px;
}
.form-button-user button:hover:after {
  background-color: var(--ac-primary-green);
}
.form-button-user button:hover {
  background: transparent;
  color: var(--ac-primary-green);
}
.form-button-user {
  display: flex;
  justify-content: center;
}
/* #manage-user-profile .cancel-close-text button:last-child {
  color: var(--ac-primary-blue);  
} */
/* #manage-user-profile .cancel-close-text button:last-child:after {
  background-color: var(--ac-primary-blue);
} */
.add-user-form .form-button-user button {
  margin: 0 33px;
}
.pending-user {
  color: var(--gold);
}

@media screen and (max-width: 1300px) {
  .edit-form .form-group {
    width: 42%;
    margin-right: 15px;
  }
}

@media screen and (max-width: 1280px) {
  .edit-form .form-group {
    width: 42%;
    margin-right: 15px;
  }

  .edit-form label {
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
  }
}

@media screen and (max-width: 1023px) {
  .edit-form label {
    font-size: 13px;
    line-height: 15px;
  }
}

@media screen and (max-width: 991px) {
  .right-tab-content {
    width: calc(100% - 50px);
  }

  .edit-form .form-group a {
    position: initial;
    margin-top: 5px;
  }

  .tabcontent {
    width: 100%;
  }

  .top-content-pf {
    padding: 20px 25px;
  }

  .pr-page-title h2 {
    font-size: 40px;
    line-height: 50px;
  }

  .pr-page-title,
  .update-profile {
    padding: 20px 25px;
  }

  .profile-form {
    padding: 0 25px;
  }

  .edit-form .form-group {
    width: 320px;
    margin-right: 15px;
    position: relative;
  }

  .edit-form .form-group:nth-child(even) {
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .search-box {
    margin-bottom: 20px;
    width: 100%;
  }

  .search-box input[type="text"] {
    width: 100%;
  }

  .top-content-pf {
    flex-wrap: wrap;
    padding: 20px 15px 10px;
  }

  .pr-page-title {
    padding: 10px 15px 20px;
  }

  .pr-page-title h2 {
    font-size: 34px;
    line-height: 44px;
  }

  .pr-page-title h2 img {
    margin-right: 20px;
  }

  .update-profile {
    padding: 20px 15px;
  }

  .edit-pic {
    margin-bottom: 15px;
  }

  .other-info h4 {
    font-size: 22px;
    line-height: 26px;
  }

  .profile-form {
    padding: 0 15px;
  }

  .edit-form .form-group {
    width: 100%;
    margin-right: 0;
  }

  .edit-form .form-group input,
  .edit-form .form-group select {
    max-width: 100%;
  }

  .edit-form label {
    font-size: 12px;
    line-height: 10px;
    margin-bottom: 8px;
  }

  .edit-icon {
    width: 26px;
    height: 26px;
    padding: 4px;
  }

  .other-info p {
    margin-bottom: 10px;
  }
  .bill-method {
    padding-top: 15px;
  }
  .tab-content .modal {
    position: fixed;
  }
  .update-profile .modal-dialog {
    width: 95%;
    width: 95%;
    height: auto; 
  }
  .update-profile .modal-body {
    padding: 50px 10px;
  }
  .update-profile .modal-content {
    width: 100%;
    height: 100%;
  }
  .heading-details {
    padding-left: 15px;
  }
  .heading-details button {
    margin: 15px 0 0;
  }
  .manage-users-details table td:first-child, .manage-users-details table th:first-child {
    padding-left: 30px;
  }
  .seat-limit {
    margin: 35px 30px 20px;
  }
  .manage-users-details .modal-content {
    padding: 20px;
  }
  .form-button-user button {
    margin: 15px 10px 0;
  }
}

@media screen and (max-width: 360px) {
  .user-info {
    flex-wrap: wrap;
  }
}

/* -------------------- Profile page css end -------------------- */

/* -------------------- Industry Leaders page css start -------------------- */
.leader-content {
  background: var(--ac-gray);
  padding: 24px;
  margin: 48px 56px;
  min-height: 456px;
}

.leader-content h3 {
  color: var(--ac-secondary-black);
  font-weight: 400;
  font-size: 22px;
  line-height: normal;
  margin: 0px 0 20px;
  font-family: 'Helvetica Neue Light';
}

.table-content {
  overflow: auto;
  max-height: 360px;
  padding-right: 10px;
}

.table-content th {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: var(--secondary-color);
  text-align: left;
  padding: 0px 10px 15px 0;
}

.table-content table {
  width: 100%;
}

.table-content table tbody td {
  border-top: 1px solid rgba(188, 188, 188, 0.5);
  padding: 15px 0;
}

.table-content table tbody td button {
  background: var(--ac-gray);
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: var(--ac-secondary-black);
  width: 104px;
  margin: 0;
  border: 1px solid rgba(188, 188, 188, 0.5);
  border-radius: 1px;
  margin-left: 5px;
}

.table-content table tbody td button:hover {
  background: var(--ac-primary-green);
  border-color: var(--ac-primary-green);
  color: var(--white);
}




.tabTwo {
  padding: 0 56px;
}

.tabTwo .tabTwo-list {
  margin: 0px;
  padding: 0px;
}

.tabTwo .tabTwo-list li {
  display: inline-block;
  list-style-type: none;
  border-bottom: 1px solid var(--white);
  margin-right: 40px;
}

.tabTwo .tabTwo-list li a {
  color: var(--secondary-color);
  display: block;
  padding: 3px 20px 10px;
}

.tabTwo .tabTwo-list li a h3 {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
}

.tabTwo .tabTwo-list li.active,
.tabTwo .tabTwo-list li.hover {
  border-bottom: 1px solid var(--ac-secondary-black);
}

.tabTwo .tabTwo-list li.active a,
.tabTwo .tabTwo-list li a:hover {
  color: var(--ac-secondary-black);
}

.tabTwo .tabTwo-panel {
  display: none;
  background-color: var(--white);
  color: #666;
  min-height: 150px;
  padding: 48px 0;
}

.tabTwo .tabTwo-panel.active {
  display: block;
}

.tabTwo .tabTwo-panel h4 {
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  color: var(--ac-secondary-black);
}

.tabTwo .tabTwo-panel .properties-selector {
  padding: 0;
  margin: 20px 0 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.tabTwo .tabTwo-panel .properties-selector li {
  width: 25%;
  padding-right: 20px;
}

.tabTwo .tabTwo-panel .properties-selector select {
  background-position: 93% center;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.tabTwo .tabTwo-panel .properties-selector a {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--ac-secondary-black);
  text-decoration: underline;
}

.tabTwo .tabTwo-panel .properties-selector .form-group {
  height: 48px;
}

.tabTwo .tabTwo-panel .properties-selector .form-group .chosen-container {
  background: #FFFFFF;
  border: 1px solid rgba(188, 188, 188, 0.5);
  border-radius: 1px;
  color: var(--ac-secondary-black);
  margin-bottom: 24px;
  padding: 15px 25px 15px 15px;
  max-width: 430px;
  width: 100%;
  background-image: url(../images/down-arrow.svg);
  background-repeat: no-repeat;
  background-position: 96% center;
  background-size: 10px 10px;
  appearance: none;
  height: 48px;
  display: flex;
  position: relative;
}

.tabTwo .tabTwo-panel .properties-selector .form-group .chosen-container-multi .chosen-choices li.search-field input[type="text"] {
  color: var(--ac-secondary-black);
}

.properties-selector .form-group .chosen-container-multi .chosen-choices {
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  outline: none;
  height: 30px;
  background: transparent;
  border: none;
}

.properties-selector .form-group .chosen-container-active .chosen-choices {
  border: none;
  box-shadow: none;
}

.properties-selector .form-group .chosen-container .chosen-drop {
  border: 1px solid var(--secondary-color);
  left: 0;
}

.tabTwo .tabTwo-panel .chosen-drop .chosen-results li {
  width: 100%;
  background: var(--white);
  color: var(--ac-secondary-black);
  padding: 10px;
}

.tabTwo .tabTwo-panel .chosen-drop .chosen-results li.highlighted {
  background: var(--ac-gray);
}




.notify-pop-up-content {
  max-height: 75vh;
  overflow: auto;
  padding-right: 10px;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  box-shadow: none;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}

.notifi-history-list {
  display: flex;
}

.with-para {
  display: flex;
  align-items: flex-start;
  width: 75%;
}

.with-para img {
  margin-right: 24px;
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

.with-para p {
  color: var(--ac-secondary-black);
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
}

.with-time {
  display: flex;
  margin-left: auto;
}

.with-time p {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: var(--secondary-color);
}

/* modal pop up css end */

@media screen and (max-width: 991px) {
  .leader-content {
    margin: 25px;
  }

  .tabTwo {
    padding: 0 25px;
  }

  .tabTwo .tabTwo-list li {
    margin-right: 15px;
  }

  .tabTwo .tabTwo-list li:last-child {
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {

  .leader-content {
    margin: 25px;
    padding: 15px;
  }

  .leader-content h3 {
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 30px;
  }

  .tabTwo {
    padding: 0 25px;
  }

  .table-content th {
    padding: 0px 30px 15px 0;
  }

  .tabTwo .tabTwo-panel .properties-selector {
    flex-direction: column;
  }

  .tabTwo .tabTwo-panel .properties-selector li {
    width: 100%;
    padding: 0 0 15px;
  }

  .tabTwo .tabTwo-list {
    display: flex;
    flex-direction: column;
  }

  .tabTwo .tabTwo-list li {
    margin: 0 0 15px;
  }
}

/* -------------------- Industry Leaders page css end -------------------- */

/* -------------------- sidebar panel css start -------------------- */
@media screen and (max-width: 991px) {
  .side-bar-menu .login-logo {
    padding: 30px 15px 10px;  
  }
  
  .tab-panel-menu .tablinks {
    padding: 8.5px 8.5px 8.5px 10px;
    margin-bottom: 15px;
  }
  .tab-panel-menu .tablinks span {
    display: flex;
  }

  button.tablinks svg {
    margin-right: 10px;
  }
}
@media screen and (max-width: 767px) {
  .tab-panel-menu .tablinks span {
    display: none;
  }
}
/* -------------------- sidebar panel css end -------------------- */


/* -------------------- reset password page css start -------------------- */
.reset-pass .subhead {
  color: var(--secondary-color);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 21px;
}
.left-content .reset-pass form {
  margin-bottom: 23px;
}
.set-new-pass .subhead {
  color: var(--secondary-color);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 46px; 
}

.left-content .set-new-pass form {
  margin-bottom: 20px;  
}

/* -------------------- reset password page css end -------------------- */

/* -------------------- Insights page css starts -------------------- */
.insights-tabs {
  /* margin: 48px 0; */
  margin: 48px 56px;
}
.insights-tabs .nav-pills .nav-link.active, .insights-tabs .nav-pills .show>.nav-link {
  background: transparent;
  color: var(--black);
  border-bottom: 1px solid var(--ac-secondary-black);
  border-radius: 0;
}
.insights-tabs > ul  {
    justify-content: space-between;
}
.insights-tabs li.nav-item {
    width: auto !important;
}
.insights-tabs .nav-link {
  color: var(--secondary-color);
  line-height: 22px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 16px;
}
.insights-tabs .nav-link:hover{
  color: var(--black);
}
.insights-tabs .tab-content h4 {
  font-size: 28px;
  line-height: 33px;
  color: var(--ac-secondary-black);
}

/*multi select dropdown css*/
.dropdown-tab-content {
  margin: 20px 0 75px;
  flex-wrap: wrap;
}
.dropdown-tab-content .dropdown {
  margin-right: 40px;
}
.dropdown-tab-content .dropdown .dropdown-toggle {
  border: 1px solid #DEDEDE;
  border-radius: 1px;
  margin-bottom: 0;
  width: 240px;
  text-align: left;
  color: var(--ac-secondary-black);
  padding-right: 17px;
  max-width: 100%;
  font-size: 14px;
  font-weight: 500;
  z-index: 9999;
}
.dropdown-tab-content .dropdown .dropdown-toggle:focus {
  box-shadow: none;
  border: 1px solid #1438F5;
}
/* .dropdown-tab-content .dropdown .dropdown-toggle:active {
  box-shadow: none;
  border: 1px solid #000 !important;
} */
.dropdown-tab-content .dropdown .dropdown-menu {
  width: 100%;
  margin-top: 0 !important;
  border-radius: 0;
  padding-bottom: 0;
  /* filter: drop-shadow(0px 30px 15px rgba(0, 0, 0, 0.13)); */
  /* box-shadow: none; */
}
.dropdown-tab-content .dropdown .dropdown-menu input[type="search"] {
  margin-bottom: 0;
  height: 36px;
}
.dropdown-tab-content .dropdown .dropdown-menu li:not(:last-child) {
  padding: 10px 15px;
  position: relative;
}
.dropdown-tab-content .dropdown .dropdown-menu li:not(:last-child):hover {
  background: #F8F8F8;
  cursor: pointer;
}
.dropdown-tab-content .dropdown .dropdown-menu li:first-child:hover {
  background: transparent;
  cursor: auto;
}
.dropdown-tab-content .dropdown li a {
  display: block;
}
.dropdown-tab-content .dropdown .dropdown-menu li label {
  font-size: 14px;
  color: var(--ac-secondary-black);
  display: block;
}
.dropdown-tab-content .dropdown .dropdown-menu .apply-btn {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid rgba(188, 188, 188, 0.5);
  padding: 12px;
}
.dropdown-tab-content .dropdown .dropdown-menu .apply-btn button {
  margin-bottom: 0;
  max-width: 102px;
  background: var(--ac-primary-green);
  border-radius: 1px;
  font-size: 12px;
  line-height: 14px;
  padding: 7px 20px;
  color: var(--white);
}
.dropdown-tab-content .dropdown .dropdown-menu .apply-btn button:hover {
  background-color: var(--ac-secondary-black);
}
.dropdown-tab-content .dropdown input[type="checkbox"] {
  width: 96%;
  height: 38px;
  margin-bottom: 0;
  display: block;
  top: 12px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  color: var(--ac-secondary-black) !important;
}
.dropdown-tab-content .dropdown input[type="checkbox"]:focus {
  box-shadow: none;
}
.styled-checkbox {
  position: absolute;
  opacity: 0;
  z-index: 2;
}
.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.styled-checkbox + label:before {
  content: "";
  margin-right: 6px;
  display: inline-block;
  vertical-align: text-top;
  width: 16px;
  height: 16px;
  border: 1px solid rgba(188, 188, 188, 0.5);
}
.styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 3px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}
.styled-checkbox:checked + label:before {
  background: var(--ac-secondary-black);
}
.dropdown-toggle::after {
    background: url(../images/down-arrow.svg) no-repeat;
    width: 14px;
    height: 8px;
    border: 0 none;
    right: 5px;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    opacity: 0.5;
}
/*multi select dropdown css*/

.reset-filter {
  width: auto;
  color: var(--ac-secondary-black);
  background: transparent;
  font-size: 14px;
  line-height: 17px;
  border-bottom: 1px solid;
  border-color: var(--ac-secondary-black);
  border-radius: 0;
  padding:0;
  margin-left: 37px;
  margin-top: 8px;
  margin-bottom: 10px;
}
.reset-filter:hover{
  color: var(--ac-primary-blue);
 border-color: var(--ac-primary-blue);
}
.not-subscribed-box {
  background: linear-gradient(89.37deg, #F8F8F8 12.09%, #F2F2F2 77.47%);
  box-shadow: 0px 30px 82px rgba(0, 0, 0, 0.13);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 111px 15px;
  margin: 65px 0;
}
.not-subscribed-box h4 {
  color: var(--black);
  max-width: 580px;
  margin: 0 auto;
  text-align: center;
}
.not-subscribed-box h4:last-child {
  margin-bottom: 0;
}
.not-subscribed-box h4 a {
  text-decoration: underline;
  color: #0529E7;
}
.not-subscribed-box h4 a:hover {
  color: var(--black);
}
.lockIcon {
  background: white;
  padding: 5px;
  border-radius: 100%;
  width: 28px;
  height: 28px;
  object-fit: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 29px;
}
.lockIcon img {
  width: 14px;
}
@media screen and (max-width: 1280px) {
  .insights-tabs > ul {
      justify-content: space-between;
  }
}
@media screen and (max-width: 991px) {
  .insights-tabs {
    margin: 25px;
  }
  .commerce-tab-content {
    margin: 20px 0 42px;
  }
  .dropdown-tab-content .dropdown{
    margin-right: 0;
    max-width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .commerce-tab-content {
    margin: 20px 0 52px;
  }
  .insights-tabs {
    margin: 25px;
    padding: 15px;
  }
  .insights-tabs .nav.nav-pills {
    flex-direction: column;
  }
  .insights-tabs .nav-link {
    text-align: left;
    margin-bottom: 10px;
    line-height: 20px;
  }
  .not-subscribed-box {
    padding: 40px 15px;
  }
  .insights-tabs .tab-content h4 {
    font-size: 20px;
  }
}

@media screen and (max-width: 479px) {
  .commerce-tab-content {
    flex-direction: column;
  }
  .reset-filter {
    margin-left: 0;
    margin-top: 20px;
  }
}

@media screen and (max-width: 300px) {
  .dropdown-toggle {
    white-space: normal;
    text-align: left;
  }
}

/* -------------------- Insights page css end -------------------- */

/* -------------------- Notifications Drawer css starts  -------------------- */
.offcanvas.notification-drawer {
  width: 48vw !important;
  box-shadow: 0px 30px 82px rgba(0, 0, 0, 0.13);
  border: 0 none;
  padding: 40px 56px 20px 56px;
}
.offcanvas.notification-drawer .offcanvas-header {
    border-bottom: 1px solid rgba(188, 188, 188, 0.5);
    padding: 0 0 20px;
    align-items: flex-start;
    margin-bottom: 32px;
}
.offcanvas.notification-drawer .offcanvas-header h2 {
  font-size: 28px;
  line-height: 64px;
  color: var(--ac-secondary-black);
  font-family: 'Helvetica Neue Light';
}
.offcanvas.notification-drawer .offcanvas-header .btn-close {
  margin-top: -0.5rem;
  margin-right: -1.5rem;
}
.offcanvas.notification-drawer .offcanvas-body {
  padding: 0;
}
.offcanvas.notification-drawer .offcanvas-body .info {
  margin-bottom: 24px;
}
.offcanvas.notification-drawer .offcanvas-body .date {
  margin-bottom: 25px;
}
.offcanvas.notification-drawer .offcanvas-body .date span {
  color: var(--secondary-color);
  font-size: 14px;
}
.offcanvas.notification-drawer .offcanvas-body .logo-info {
  position: relative;
  width: 75px;
}
.offcanvas.notification-drawer .logo-info .img-notify {
  width: 12px;
  height: 12px;
  background: #F06341;
  display: flex;
  border-radius: 50%;
  border: 2px solid var(--white);
  position: absolute;
  right: 2px;
  top: 1px;
}
.offcanvas.notification-drawer .desc-info {
    max-width: 422px;
    margin-left: 24px;
    align-items: center;
    display: flex;
}
.offcanvas.notification-drawer .time-info {
  color: var(--secondary-color);
  margin-left: auto;
}

@media screen and (max-width: 1024px) {
  .offcanvas.notification-drawer {
    width: 610px;
    padding: 30px;
  }
  .offcanvas.notification-drawer .offcanvas-header h2 {
    font-size: 40px;
    line-height: 54px;
  }
}

@media screen and (max-width: 767px) {
  .offcanvas.notification-drawer {
    width: 100%;
    padding: 20px;
  }
  .offcanvas.notification-drawer .offcanvas-header h2 {
    font-size: 34px;
    line-height: 44px;
  }
  .offcanvas.notification-drawer .offcanvas-body .logo-info {
    width: 34px;
  }
  .offcanvas.notification-drawer .desc-info {
    max-width: 60%;
  }
  .offcanvas.notification-drawer .time-info {
    padding-right: 10px;
  }
  .offcanvas.notification-drawer .offcanvas-header .btn-close {
    margin-top: -1rem;
    margin-right: -1rem;
  }
}
/* -------------------- Notifications Drawer css ends  -------------------- */

/* -------------------- Insights page graph css start -------------------- */
.graph-data-acu h3 {
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  margin-bottom: 20px;
  padding: 21px 0 0 20px;
  color: var(--ac-secondary-black);
  font-family: 'Helvetica Neue Light';
  width: 80%;
}

.two-select-boxes {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
}

.two-select-boxes .form-select {
  max-width: 240px;
  margin-right: 34px;
  height: 40px;
}

.graph-data-insights {
  padding: 15px 0;
}
.graph-data-insights button {
  background: transparent;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  padding: 0;
}

.graph-data-insights button:after {
  content: none;
}

.graph-data-insights button p {
  position: relative;
}

.graph-data-insights button p::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: -3px;
  border-radius: 0;
  border: none;
  background: var(--ac-secondary-black);
  left: 0;
}

.graph-data-insights button svg {
  margin-left: 13px;
  margin-right: 0;
}

.graph-data-insights button:hover p {
  color: var(--ac-primary-blue);
}
.graph-data-insights button:hover p::after {
  background-color: var(--ac-primary-blue);
}
.graph-data-insights button:hover svg circle {
  fill: var(--ac-primary-blue);
}

.graph-images {
  margin: 0 0 30px;
}

.graph-line ul {
  margin: 0 0 0 11px;
  padding: 0;
}

.graph-line li {
  list-style: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
}

.graph-line li span {
  width: 8px;
  height: 8px;
  border-radius: 2px;
  margin-right: 8px;
}

.color-green {
  background-color: #49CC86;
}

.color-blue {
  background-color: #3349FF;
}

.graph-data-insights .dropdown-menu {
  border: none;
  background-color: var(--white);
  min-width: auto;
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 10%);
  z-index: 1;
  flex-direction: column;
  padding: 0;
}

.graph-data-insights .dropdown-menu .dropdown-item {
  padding: 9px 16px;
}

.graph-data-insights .dropdown-menu.show::after {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--white);
  top: -10px;
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
}



.graph-details {
  padding: 70px 55px 0;
}

.author-section .accordion .accordion-button::after {
  background-image: url(../images/plus-icon.svg);
  width: 40px;
  height: 40px;
  background-size: contain;
}

.author-section .accordion .accordion-button:not(.collapsed)::after {
  background-image: url(../images/minus-icon.svg);
}

.author-section .accordion .accordion-item {
  background-color: var(--ac-gray);
  border: none;
  border-radius: 0;
}

.author-section .accordion .accordion-button {
  background-color: var(--ac-gray);
  border: none;
  box-shadow: none;
  color: var(--ac-secondary-black);
  margin-bottom: 0;
  font-weight: 500;
  font-size: 17px;
  line-height: 22px;
  padding: 40px 24px 0;
  border-radius: 0;
}

.author-section .accordion .accordion-button.collapsed {
  border-radius: 0;
  padding: 40px 24px 40px;
}

.author-section .accordion .accordion-body {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  padding: 25px 24px 58px;
}

.tab-content .graph-details h4 {
  font-weight: 400;
  font-size: 22px;
  line-height: 38px;
  margin-bottom: 28px;
  margin-left: 25px;
}

.author-section .row {
  /* align-items: center; */
  align-items: flex-start;
}
.author-img-details img{
  padding: 16px 0 0 25px;
  width: 100%;
  max-height: 270px;
  object-fit: cover;
  
}
.author-img-details {
  position: relative;
  text-align: center;
}

.author-img-details em {
  font-weight: 300;
  font-size: 14px;
  line-height: 32px;
  margin: 0 auto;
  display: block;
  color: var(--ac-secondary-black);
  position: absolute;
  left: 0;
  right: 0;
  bottom: -38px;
}

.author-para {
  margin: 40px 0 0;
  /* border-bottom: 1px solid rgba(188, 188, 188, 0.5); */
  padding-bottom: 50px;
}

.author-para p {
  margin-bottom: 28px;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--black);
  margin-left: 26px;
}
.author-para p:last-child {
  margin-bottom: 0;
}

.share-options {
  padding: 24px 70px 50px;
}
.share-options ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.share-options ul a {
  background-color: var(--white);
  height: 56px;
  width: 56px;
  border: 1px solid rgba(188, 188, 188, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin: 0 20px 15px 0;
}

.share-options ul a:hover {
  background-color: var(--ac-primary-blue);
}

.share-options ul a:hover svg path {
  stroke: var(--white);
}
@media (max-width: 1024px) {
  .two-select-boxes .dropdown {
      margin-right: 20px;
  }
}
@media (max-width: 991px) {
  .graph-details {
    padding: 70px 15px 0;
  }
  .share-options {
    padding: 24px 25px 50px;
  }
  .graph-data-acu h3 {
    padding: 21px 0 0 0;
  }
  .two-select-boxes{
    flex-wrap: wrap;
  }
  .two-select-boxes .dropdown {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
  }
  .author-section .accordion .accordion-button.collapsed {
    padding: 20px 24px 20px;
  }
  .author-para p {
      line-height: 22px;
  }
}

@media (max-width: 767px) {
  .insights-tabs {
    margin: 20px 15px;
  }
  .graph-data-acu h3 {
    font-size: 26px;
    line-height: 32px;
  }
  .two-select-boxes {
    flex-direction: column;
  }
  .two-select-boxes .form-select {
    margin-right: 0;
  }
  .graph-data .row {
    margin-right: calc(-.5 * var(--bs-gutter-x));
    margin-left: calc(-.5 * var(--bs-gutter-x));
  }

  .share-options {
    padding: 24px 15px 50px;
  }
  .graph-details {
    padding: 50px 0 0;
  }
  .tab-content .graph-details h4 {
    font-size: 24px;
    line-height: 30px;
  }
  .share-options ul a {
    height: 35px;
    width: 35px;
  }
  .share-options ul a svg {
    width: 18px;
  }
  .author-section .accordion .accordion-button {
    padding: 40px 15px 0;
  }
  .author-section .accordion .accordion-body {
    padding: 25px 15px 40px;
    font-size: 14px;
    line-height: 24px;
  }
  .author-section .accordion .accordion-button::after {
    width: 20px;
    height: 20px;
  }
  .author-img-details em {
    position: initial;
    margin: 10px 0;
  }
}
/* -------------------- Insights page graph css end -------------------- */

/* -------------------- Insights industry leader selected css start -------------------- */
.back {
  margin-bottom: 24px;
}
.back a {
  color: var(--ac-secondary-black);
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}
.back a img {
  margin-right: 15px;
  width: 22px;
}
.leader-selected-details h5 {
  color: var(--ac-secondary-black);
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 32px;
}
.leader-selected-details p {
  color: var(--ac-secondary-black);
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 37px;
}
.leader-selected-details p:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 767px) {
  .leader-selected-details p {
    margin-bottom: 20px;  
  }
  .leader-selected-details p:last-child {
    margin-bottom: 0;
  }   
}
/* -------------------- Insights industry leader selected css end -------------------- */

body {
  background: var(--white) !important;
}
body .modal-backdrop.show, 
body .offcanvas-backdrop.fade {
  opacity: 0.5;
  background-color: #fff;
}

.tab-content .inner-content {
  /* max-height: 80vh; */
  overflow-y: auto !important;
  padding-right: 0;
}

#sidebar-menu ul li a {
  border-radius: 0;
  justify-content: flex-start;
  background: transparent;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: var(--secondary-color);
  border-left: 2px solid var(--ac-gray);
  padding: 9px 9px 9px 24px;
  text-align: left;
  display: flex;
  margin: 0;
  align-items: center; 
  display: flex !important;
}

#sidebar-menu ul li a:hover {
  color: var(--ac-secondary-black);
  border-color: var(--ac-secondary-black);
}

#sidebar-menu ul li a.active {
  color: var(--black);
  border-color: var(--ac-secondary-black);
  background-color: transparent;
}

#setting .update-profile {
  padding: 50px 0;
}

#settings .profile-form {
  padding: 0 0 50px;
}

.common-box input, .common-box .form-control {
  border-radius: 1px !important;
}

.btn-success {
  background-color: var(--ac-primary-green);
  border: none;
}

.inner-content .table-content table tbody td {
  padding: 0.75rem 1.5rem 0.75rem 0.75rem;
}

.detailed-insights .dropdown-toggle-icon {
  position: absolute;
  right: 15px;
  transform: translateY(-50%);
  margin-top: 0;
  top: 50%;
}

.side-bar-menu .login-logo a, .login-logo a {
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  color: var(--ac-secondary-black);
  font-family: 'Helvetica Neue Light';
}

.tab-panel-menu li a img {
  margin-right: 10px;
  opacity: 0.5;
  width: 22px;
}

.tab-panel-menu li a.active img, .tab-panel-menu li a:hover img {
  opacity: 1
}

.tab-panel-menu li a:hover span {
  color: var(--ac-secondary-black) !important;
}

/* .cancel-close-text {
  display: flex;
  width: 100%;
  justify-content: center !important;
  margin: 51px auto 0;
} */

.modal-content .cancel-close-text button {
  margin: 0 20px;
}

.log-in-details .tab-content {
  height: auto;
}

.modal-body p {
  font-size: 16px;
  line-height: 19px;
}

/* .login-details .button-type button.button-with-bg:hover {
  background: var(--ac-primary-green);
}

.login-details .button-type button.button-with-bg {
  background: #BCBCBC;
} */

.left-content button {
  border-radius: 1px;
  border: none;
  width: 100%;
  padding: 8.5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  transition: all ease-in-out 0.3s;
}

.detailed-insights h4 {
  color: #0F0F0F;
  font-family: 'Helvetica Neue Light';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
}

.pr-page-title {
  padding: 10px 0 24px 56px !important;
  margin-bottom: 48px;
  margin-right: 10px;
}

.tab-content #settings .inner-content {
 margin: 0px 0 0 0;
}

.detailed-insights {
  margin: 0 56px;
}

#setting.manage-users-details {
  margin-left: 56px;
}

.update-profile2 {
  margin-left: 56px;
}

.update-profile2, .manage-users-details {
  padding: 0 15px
}

#page-header-user-dropdown .mdi-chevron-down::before {
  font-size: 28px;
  width: auto;
  height: auto;
  line-height: normal;
  color: #000;
}

#page-header-notifications-dropdown img {
  margin-right: 0;
}

#page-header-notifications-dropdown {
  height: auto;
  width: auto;
  padding: 0;
  position: relative;
  margin-right: 16px;
}

.noti-icon .badge {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  right: -3.5px !important;
  border: 1px solid #fff;
  width: 8px;
  height: 8px;
}

#adminsettings .inner-content .pr-page-title + div {
  margin: 0 56px;
}
#adminsettings .inner-content {
  margin: 48px 0;
  padding: 0 15px;
}

.form-label2{
  font-family: 'Helvetica Neue';
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 12px;
  display: flex;
  color:#878787;
}

.pf-notification #page-header-notifications-dropdown {
  height: auto;
  width: auto;
  padding: 0;
  position: relative;
  margin-top: 1px;
  margin-right: 0;
  left: 3px;
}

.header-profile-user {
  height: 40px;
  width: 40px;
  margin: 0 2px 0 12px;
  background-color: #f6f6f6;
  padding: 3px;
  margin-right: 0;
}

#page-header-user-dropdown .mdi-chevron-down {
  position: relative;
  top: 6px;
}

.profile-form input, .profile-form select {
  max-width: 100%;
  border-radius: 1px;
}

@media screen and (max-width: 991px) {
  #sidebar-menu ul li a {
      display: flex;
  }
  .tab-panel-menu .tablinks span {
      font-size: 12px;
  }
  #adminsettings .inner-content .pr-page-title + div {
      margin: 0 15px 80px;
  }    
  #settings  .pr-page-title {
    padding: 10px 30px 24px !important;
  }
  #settings .update-profile2 {
    margin-left: 0;
  }
  #setting.manage-users-details {
    margin-left: 15px;
  }
  .side-bar-menu .login-logo a, .login-logo a {
    font-size: 24px;
    line-height: 30px;
  }
  #settings .manage-users-details table th:first-child, #settings .manage-users-details table td:first-child {
    padding-left: 0.75rem;
  }
  #adminsettings .manage-users-details table th:first-child, #adminsettings .manage-users-details table td:first-child {
    padding-left: 0.75rem;
  }
}

@media screen and (max-width: 767px) {
  #sidebar-menu ul li a {
      padding: 9px 9px 9px 20px;
  }
  .top-content-pf {
      padding: 10px 10px 10px 15px !important;
  }
  .pr-page-title {
      padding: 10px 15px 24px !important;
  }
  #adminsettings .inner-content .pr-page-title + div {
      margin: 0 15px 30px;
  }
 .tab-panel-menu li a img {
     width: 20px;
     margin-right: 0;
  }
 .tab-panel-menu li a span {
     display: none;
  }
 .update-profile2 {
     margin-left: 0;
  }
 #setting.manage-users-details {
     margin-left: 0;
  }
  #settings .pr-page-title {
    padding: 10px 15px 24px !important;
  }
  #settings .top-content-pf {
    padding: 10px 10px 10px 15px !important;
  }
  #sidebar-menu ul.tab-panel-menu li a {
    padding: 9px 9px 9px 20px;
  }
  #adminsettings .pr-page-title {
      padding: 10px 15px 24px !important;
  }
  #adminsettings .top-content-pf {
      padding: 10px 10px 10px 15px !important;
  }
  #adminsettings .inner-content {
    max-height: 70vh;
    padding: 0 15px 50px;
  }
  .pf-notification {
    justify-content: flex-end;
    width: 100%;
  }
  .search-box {
     display: none;
  }
  .search-box label.search-label {
    float: none;
    width: 100%;
 }
}

@media screen and (max-width: 991px) and (orientation: landscape) {
  .left-content {
      overflow: unset;
  }
  .log-in-details {
      overflow: auto;
      height: 85vh;
      padding-top: 15px;
  }
  .form-header {
		position: initial;
	}	
}

.header-item .arrow-down {
  width: 18px;
}

.role{
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: var(--secondary-color);
  margin-bottom: 8px;
}

.profile-form input{
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #0F0F0F !important;
}

.link_hover{
  text-decoration: underline !important;
}

#adminsettings .manage-users-details table th:first-child {
  padding-left: 0.75rem;
}

#adminsettings .pf-notification #page-header-notifications-dropdown {
  margin-bottom: 0;
}

#settings .manage-users-details table th:first-child {
  padding-left: 0.75rem;
}

.del{
  text-decoration: underline;
  color: var(--black);
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

#settings .manage-users-details table th:first-child, #settings .manage-users-details table td:first-child {
  padding-left: 40px;
}

#adminsettings .manage-users-details table th:first-child, #adminsettings .manage-users-details table td:first-child {
  padding-left: 40px;
}

table tr td p {
  margin: 0;
}

.manage-users-details table tr:first-child td {
  padding: 1.5rem 0.75rem;
}

@media (max-width: 767px) {
	.manage-users-details .heading-details button {
	  max-width: 100%;
	}
	.seat-limit {
	  margin: 35px 0px 20px;
	  padding: 15px;
	}
	.row.heading-details {
	  padding: 0;
	}
	#settings .manage-users-details table th:first-child, #settings .manage-users-details table td:first-child {
	  padding-left: 0.75rem !important;
	}
}

@media (max-width: 991px) {
	.detailed-insights {
	  margin: 0 30px;
	}
	.reset-filter {
		margin-left: 15px;
		margin-top: 0;
	}
}

/* tooltip css start */
.tooltipDesign {
  font-family: 'Helvetica Neue';
  background: #FFFFFF;
  box-shadow: 0px 12px 30px rgb(0 0 0 / 11%);
  border-radius: 4px;
  width: 188px;
  padding: 14px 10px;
}
.tooltipDesign .tt-head {
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #0F0F0F;
  margin: 0 0 20px;

}
.tooltipDesign ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.tooltipDesign li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.tooltipDesign li span {
  width: 14px !important;
  height: 14px !important;
  border-radius: 1px;
  margin: 3px 8px 0 0;
}
.tooltipDesign ul p {
  margin: 0 10px 0 0;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #0F0F0F;
  display: flex;
  align-items: flex-start;
  width: 80%;
}
.tooltipDesign .typetooltip{
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #0F0F0F;
}
.tooltipDesign .valtooltip{
  font-weight: 400;
  font-size: 10px;
  line-height: 10px;
  color: #6A6A6A;
}
.tooltipDesign li .color1-dot {
  background: #3349FF;
}
.tooltipDesign li .color2-dot {
  background: #FD9502;
}
.tooltipDesign li .color3-dot {
  background: #49CC86;
}
.tooltipDesign li .color4-dot {
  background: #FFE500;
}
.tooltipDesign li .color5-dot {
  background: #004998;
}
.tooltipDesign ul label {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #0F0F0F;
}

#RRDropdown li{
  line-height: 17px;
}
.inner-content .leader-content table th {
  padding-top: 0;
}

.search {
  margin-right: 16px;
  width: 16px
}

span.deleteicon {
    position: relative;
    display: inline-flex;
    align-items: center;
}
span.deleteicon span {
    position: absolute;
    display: block;
    right: 3px;
    width: 12px;
    height: 12px;
    background: url(../images/search_close_icon.svg) no-repeat !important;
    cursor: pointer;
    margin:0px 5px 0px;
    background-size: cover !important;
    background-position: 0 0;
}
span.deleteicon input {
    padding-right: 18px;
    box-sizing: border-box;
}
@media (max-width: 1190px) {
  .table-content table tbody td button{
      margin-left: 0px;
      padding: 8px 0px;
      width: 80px;
  }
}
@media (max-width: 1200px) {
  .graph-data-acu h3{
     width: 70%;
  }
}
.right-content {
  background: #000;
  overflow: hidden;
  height: 100vh;
  align-items: center;
  display: flex;
  padding: 60px 50px;
}

.right-content h4{
  font-family: 'Helvetica Neue Light';
  font-weight: 300;
  font-size: 32px;
  line-height: 39px;
  color: #FFFFFF;
  margin-bottom: 30px;
}
.right-content h5{
  font-family: 'Helvetica Neue Light';
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
  color: #FFFFFF;
  margin-bottom: 45px;
}
.login_detail{
  margin-bottom: 40px !important;
}
.right-content .login_detail li{
  display: block;
  font-family: 'Helvetica Neue Light';
  font-size: 14px;
  line-height: 27px;
  color: #FFFFFF;
  padding-left: 35px;
  position: relative;
  margin-bottom: 10px;
}
.right-content .login_detail li::after{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  /* margin: auto 0; */
  content:url(../images/right_circle_icon.svg);
  width: 20px;
  height: 20px;
  font-family: 'Helvetica Neue Light';
}
.partners_section h6{
  font-family: 'Helvetica Neue Light';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 30px;
}
.right-content .partners_section p img{
  width: auto;
  height: auto;
}
.content_login{
  max-width: 550px;
  margin: auto;
}
/* .notification-drawer{
  translate: 950px 0px;
}
.notification-drawer.custom-notify{
  translate: 650px 0px;
  transition: 1.5s ease !important;
}
.notification-drawer.show{
  translate: 0px 0px;
  transition: 0.5s ease !important;
} */