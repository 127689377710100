.dropdown-flex{
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse !important;
    width: 100%;
}
/* .dropdown-tab-content {
    margin: 0px 0 10px;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;
} */
.PreviewDropdown{
    white-space: pre-wrap !important;
}
.detailed-insights {
    margin-left: 20px;
    margin-right: 10px;
}
.dropdown-tab-content .dropdown {
    margin-right: 0px;
}
.dropdown-tab-content .dropdown .dropdown-toggle {
    width: 377px !important;
}
.sub-insight-select{
    margin-right: 40px;
}
.dropdown-tab-content .dropdown .w-100{
    width: 100% !important;
}
/* heat map legend bar */
.align-center{
    margin-top: 50px;
    display: flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
}
.heatbar{
    width: 60px;
    height: 342px;
    margin-left: 30%;
}
.align-legend-text{
    position: relative;
    top: -8px;
    right: -57px;
    font-weight: 600;
}
.dropdown-tab-content .dropdown .dropdown-toggle:focus {
    box-shadow: none;
    border: 1px solid #1438F5;
}
.styled-checkbox  {
    position: absolute;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
}