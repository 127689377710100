.invalid-border{
    border: 1px solid #f46a6a !important;
}
.dropdown-tab-content .dropdown .dropdown-toggle {
    border: 1px solid #DEDEDE;
    border-radius: 1px;
    margin-bottom: 0;
    width: 431px;
    height: auto;
    text-align: left;
    color: var(--ac-secondary-black);
    padding-right: 17px;
    max-width: 100%;
    font-size: 14px;
    font-weight: 500;
    z-index: 999;
}

.dropdown-Checkbox{
    width: 100% !important;
    padding-top: 10px;
    top: 1px !important;
    height: 50px !important;
    left: 1px;
}

/*  + Icon Button */
.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#0C9;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}
a.class, a.class:hover {
    text-decoration:underline;
    font-weight:bold;
    color:#FFF;
}

.my-float{
	margin-top:24.5px;
}