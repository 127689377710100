/*@import url('http://fonts.cdnfonts.com/css/helvetica-neue-9');*/

/* @font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 700;
    src: local('Helvetica Neue'), url('../fonts/hn/HelveticaNeueBold.woff') format('woff');
}
@font-face {
    font-family: 'Helvetica Neue';
    font-style: italic;
    font-weight: 700;
    src: local('Helvetica Neue'), url('../fonts/hn/Helvetica 76 Bold Italic.woff') format('woff');
}
@font-face {
    font-family: 'Helvetica Neue';
    font-style: italic;
    font-weight: 900;
    src: local('Helvetica Neue'), url('../fonts/hn/HelveticaNeueBlackItalic 1.woff') format('woff');
}
@font-face {
    font-family: 'Helvetica Neue';
    font-style: italic;
    font-weight: 500;
    src: local('Helvetica Neue'), url('../fonts/hn/HelveticaNeueMediumItalic.woff') format('woff');
}
@font-face {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 900;
    src: local('Helvetica Neue'), url('../fonts/hn/Helvetica95Black.woff') format('woff');
}
@font-face {
    font-family: 'Helvetica 25 UltraLight';
    font-style: normal;
    font-weight: 400;
    src: local('Helvetica 25 UltraLight'), url('../fonts/hn/Helvetica25UltraLight_22433.woff') format('woff');
}
@font-face {
    font-family: 'Helvetica 35 Thin';
    font-style: normal;
    font-weight: 400;
    src: local('Helvetica 35 Thin'), url('../fonts/hn/Helvetica35Thin_22435.woff') format('woff');
}
@font-face {
    font-family: 'Helvetica 45 Light';
    font-style: normal;
    font-weight: 400;
    src: local('Helvetica 45 Light'), url('../fonts/hn/Helvetica45Light_22437.woff') format('woff');
}
@font-face {
    font-family: 'Helvetica 55 Roman';
    font-style: normal;
    font-weight: 400;
    src: local('Helvetica 55 Roman'), url('../fonts/hn/Helvetica55Roman_22439.woff') format('woff');
}
@font-face {
    font-family: 'Helvetica 65 Medium';
    font-style: normal;
    font-weight: 400;
    src: local('Helvetica 65 Medium'), url('../fonts/hn/Helvetica65Medium_22443.woff') format('woff');
}
@font-face {
    font-family: 'Helvetica 25 UltraLight';
    font-style: italic;
    font-weight: 400;
    src: local('Helvetica 25 UltraLight'), url('../fonts/hn/Helvetica26UltraLightItalic_22434.woff') format('woff');
}
@font-face {
    font-family: 'Helvetica 35 Thin';
    font-style: italic;
    font-weight: 400;
    src: local('Helvetica 35 Thin'), url('../fonts/hn/Helvetica36ThinItalic_22436.woff') format('woff');
}
@font-face {
    font-family: 'Helvetica 45 Light';
    font-style: italic;
    font-weight: 400;
    src: local('Helvetica 45 Light'), url('../fonts/hn/Helvetica46LightItalic_22438.woff') format('woff');
}
@font-face {
    font-family: 'Helvetica 55 Roman';
    font-style: italic;
    font-weight: 400;
    src: local('Helvetica 55 Roman'), url('../fonts/hn/Helvetica56Italic_22440.woff') format('woff');
}
@font-face {
    font-family: 'Helvetica 65 Medium';
    font-style: normal;
    font-weight: 700;
    src: local('Helvetica 65 Medium'), url('../fonts/hn/Helvetica85Heavy_22449.woff') format('woff');
}
@font-face {
    font-family: 'Helvetica 65 Medium';
    font-style: italic;
    font-weight: 700;
    src: local('Helvetica 65 Medium'), url('../fonts/hn/Helvetica86HeavyItalic_22450.woff') format('woff');
}
 */
/*html {zoom: 90%;}*/

body {
  font-family: 'Helvetica Neue';
}
.my-5 {
	margin: 0px !important;	
}
.btn-google {
	border:1px solid #ced4da;
}

.btn-google:hover {
	background: #f46a6a;
	color:white;
	border:1px solid #f46a6a;
}
.text-primary{
	color: #000000 !important;
}

.left-content .button-with-bg:disabled {
  background: #BCBCBC !important;
  color: var(--white) !important;
}

.ca-stepFour .eye-off {
  cursor: pointer;
  position: absolute;
  top: 54%;
  top: 68%;
  right: 15px;
  transform: translateY(-50%);
}

.left-content form.login-details input{
  margin-bottom:0px;
}

input, .form-control{
  margin-bottom:0px;
}
.form-group{
  margin-bottom:24px;
}

.left-content{
  background: white; 
}
.sign-up p {
  font-size: 1rem; 
}
/*a {
  color: var(--ac-primary-blue) !important;
}
*/
.tab-panel-menu li a {
  color:unset !important;
  font-size: 16px !important;
}


.dropdown-menu a {
  color: #0F0F0F !important;
  font-size: 14px;
}

/* .dropdown-menu a {
  color: unset !important;
} */

.tab-panel-menu li a span {
  color:var(--secondary-color) !important;
}

.tab-panel-menu li .active i,.tab-panel-menu li .active span {
  color:var(--ac-secondary-black) !important;
}

.left-content .forgot-password {
  /*display:unset;
  position:unset;*/
}

.left-content form{
  position: unset;
  margin-bottom: 0px;
}
.col-10 {
  width: 82.33333% !important;
}

#sidebar-menu ul li ul.sub-menu li a {
  padding: 0.4rem 1.5rem 0.4rem 2rem !important;
}

.inner-content {
  margin: 48px 56px;
}
.col-md-1 {
  width: 9% !important;
}
.mar-10 {
  margin:10px;
}
.top-content-pf {
  padding: 10px 10px 10px 50px !important;
}
.pr-page-title {
  padding: 0px 56px 28px !important;
}
.cancel-close-text {
  display: flex;
  width: 100%;
  justify-content: flex-start !important;
  margin: 40px auto 0;
}

.edit-user-img {
  width: 120px;
  height: 120px;
}

.tdTheme {
  border: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding: 20px;
}

.header-profile-user {
  height: 60px;
  width: 60px;
}

.toast-close-button {
  width: unset !important;
}

.hw120 {
  height: 60px ;
  width: 60px ;
}

#page-header-notifications-dropdown {
  height: 61px;
  width: 60px;
}
#page-header-notifications-dropdown .bx-bell {
font-size: 35px !important;

}

.noti-icon .badge {
    position: absolute ;
    top: 18.7px ;
    right: 24.9px ;    
}
.was-validated .form-control:invalid, .form-control.is-invalid {
    border-color: #f46a6a;
    padding-right: calc(1.5em + 0.94rem);
    background-image: none !important;
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.235rem) center;
    background-size: calc(0.75em + 0.47rem) calc(0.75em + 0.47rem);
}
button.close {
  position: unset !important;
}

.left-content .button-without-bg:focus {
  box-shadow: inset 0px 0px 0 2px var(--ac-primary-green);
  color: var(--ac-primary-green);
}

.red {
  color: red;
  margin-left:5px;
  font-weight: 600;
  font-size: 16px;
}
.ca-stepFour .error-eye {
  cursor: pointer;
  position: absolute;
  top: 54%;
  right: 15px;
  transform: translateY(-50%);
}
.link_hover:hover,
.link_hover:focus{
  color: #007343;
}
input[type="password"] {
  padding-top: 0.47rem !important;
  padding-right: 2.75rem !important;
  padding-bottom: 0.47rem !important;
  padding-left: 0.75rem !important;
}

.passwordText{
  padding-top: 0.47rem !important;
  padding-right: 2.75rem !important;
  padding-bottom: 0.47rem !important;
  padding-left: 0.75rem !important;
}

.dropdown-toggle-icon {
    background: url(../images/down-arrow.svg) no-repeat;
    width: 14px;
    height: 8px;
    /*border: 0 none;
    right: 5px;
    top: 50%;*/
    opacity: 0.5;
    float: right;
    margin-top: 9px;
    transition: all ease-in-out 0.4s;
}

.dropdown-toggle-icon.rotate {
  transform: translateY(-50%) rotate(180deg);
  transition: all ease-in-out 0.4s;
}

.btn-page:disabled{
  color:#556EE6 !important;
}
.swal-footer {
  text-align: center !important;
}
.swal-text {
  text-align: center !important;
}
input[type="file"].line-height{
    line-height: 2rem !important;
}

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.inner-content table th {
  position: relative;
  padding: 0.75rem 1.5rem 0.75rem 0.75rem;
}
.inner-content table th span {
  position: absolute;
  right: 4px;
  display: block;
  top: 13px;
}
/* .accounts-btn .acc-btn:focus {
  box-shadow: inset 0px 0px 0 2px var(--ac-primary-green);
  color: var(--ac-primary-green);
} */
.edit-icon:fo{
  border-width: 5px;
  background: var(--ac-primary-green);
}

input {
	/*border-radius: 1px !important;*/
	color: #000000 !important;
}
input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.setting-modal {
    max-width: 606px  !important;
}

.setting-modal .modal-body {
    text-align: center;
    padding: 65px 15px 65px;
}

.modal-content {
	border-radius: 0px !important;
	color: #000000 !important;
}

.modal-content a {
	color : #2261da;
	/* font-weight: 600; */
}

.cancel-close-text button:hover:after {
  background-color: #2261da !important;
  font-weight: 600;
}
.cancel-close-text button:hover {
  color: #2261da !important;
  /* font-weight: 600; */
}
body .modal-backdrop.show {
  opacity: 0;
}
.reset-password {
	margin-top:2rem;
}
.success {
	color:#4e9c72;
}
.opacity-5 {
	opacity: .5;
}

.wishto {
  font-size: 16px;
    color: black;
}
.admin-settings-form .eye-off{
  cursor: pointer;
  position: absolute;
  top: 54%;
  top: 46%;
  right: 22px;
  transform: translateY(-50%);
}
.admin-settings-form .error-eye{
  cursor: pointer;
  position: absolute;
  top: 54%;
  top: 39%;
  right: 22px;
  transform: translateY(-50%);
}

.appbtn.button-with-bg {
  max-width: 205px;
  margin-left: auto;
  padding: 8.5px;
  font-size: 16px;
  line-height: 30px;
  background: #4ACC86 !important;
  color: #FFFFFF !important;
  border: none;
  border-radius: 0px;
  font-weight: 500;
}

.appbtn.button-with-bg:disabled {
  background: #DEDEDE !important;
  color: #FFFFFF !important;
}

.edit-user-img{
  border-color: #e7e7e7 !important;
  border-width: 1px;
}
.user-settings-form .eye-off {
  cursor: pointer;
  position: absolute;
  top: 54%;
  right: 20px;
  transform: translateY(-50%);
}
.user-settings-form .error-eye {
  cursor: pointer;
  position: absolute;
  top: 44%;
  right: 20px;
  transform: translateY(-50%);
}
.rounded-pill {
  padding: 4px !important; 
}

.disabled_bg {
  background: rgba(188, 188, 188, 0.5) !important;
}

.grey_bg {
  background: rgba(188, 188, 188, 0.5) !important;
}
/* Manage user popup blue action button */
.book-demo-button:after {
  color: #0124DA !important;
  background-color: #0124DA !important;
} 
.book-demo-button {
  color: #0124DA !important;
} 
.book-demo-link {
  text-underline-offset: 2px !important;
  text-decoration: underline !important;
  color: #0529E7 !important;
}

.book-demo-link:after {
  color: #0529E7 !important;
  margin-top: 1px !important;
  background-color: #0529E7 !important;  
}
/* Manage user popup blue action button ends */

.request-reset-button  {
    background: #BCBCBC !important;
    color: var(--white) !important;
}

.darkFont {
  font-weight: 500;
  color: #0F0F0F !important;
  font-size: 14px !important;
}

.remove-border {
  border-color: #0F0F0F !important;
}

.formadd{
  padding-left: 58px !important;
}

.table th{
  color:#0F0F0F;
}

#page-header-notifications-dropdown > span {
  background:  #4ACC86 !important;
}
.logout-model {
  display: flex;
  width: 10%;
  justify-content: space-between;
  margin: 51px auto 0;
}
.logout-model button {
  width: auto;
  background: transparent;
  padding: 0;
  margin: 0 ;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--black);
  position: relative;
}
.logout-model button {
  margin: 0 20px;
}
.logout-model button:hover {
  color: var(--ac-primary-green);
  background: transparent;
}
.logout-model button:hover:after {
  background-color: var(--ac-primary-green);
}
.logout-model button {
  margin: 0 20px;
}
.logout-model button:after {
  content: '';
  width: 100%;
  height: 1px;
  background-color: var(--black);
  position: absolute;
  bottom: -2px;
}
.manageuser-adduser-model{
  justify-content: center !important;
}
.form-control {
  border-radius: 0px;
}


.logout-request-model {
    display: flex;
    width: 40%;
    justify-content: space-between;
    margin: 51px auto 0;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
}

#logout_profile p {
  color: #000000;
  font-weight: 500;
}

.cancel-close-customized {
  display: flex;
  width: 40%;
  justify-content: flex-start !important;
  margin: 40px auto 0;
}
.emailSent {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #348E5E;

}

@media screen and (max-width: 991px) {
  #adminsettings .pr-page-title {
   padding: 10px 15px 24px !important;
 }
 .formadd {
  padding-left: 15px !important;
  padding-bottom: 80px;
}
}

.table-bordered th, .table-bordered td {
  vertical-align: middle;
}

@media screen and (max-width: 767px) {
  .logout-request-model {
    width: 100%;
    justify-content: center;
  }
  .cancel-close-customized {
    width: 100%;
    justify-content: center !important;
  }
  .side-bar-menu .login-logo a, .login-logo a {
    font-size: 18px;
    line-height: 18px;
  }
  .formadd {
    padding-left: 15px !important;
 }
}

.addUserinValid {
  font-weight: 500;
  font-size: 14px;
  color: #B2B2B1 !important;
}

.addUserinValid:after {
 background: #B2B2B1 !important; 
}
::-ms-reveal {
    display: none;
}
.mailto{
  text-decoration: underline !important;
}
#adminsettings button {
  margin: 10px 0;
}

.addPopup label {
  font-size: 14px !important;
}
.appbtn.button-with-bg.adduser:disabled{
  background: #DEDEDE !important;
}
/* .manage-users-details table td{
  padding-left: 13px !important;
} */
.dropdown .show::after {
  content: '';
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 15px solid transparent;
  top: 0px;
  position: absolute;
  right: 20px;
}
.search-box input[type="search"]::placeholder{
  color: #878787;
  opacity: 1; /* Firefox */

}

.donutChart {
  float: left;
}
.donutChart div{
  text-align: center; 
}
.dropdown-menu li.show {
  display: block;
  padding: 10px;
}
.dropdown-menu li.hide {
  display: none;
}
.graph-images div {
  margin:0px 15px 12px 15px;
}
.inner-content-donut {
    padding: 30px 120px;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    font-family: 'Helvetica Neue';
}

path.domain {
  stroke: none; 
}
.y .tick line {
  stroke: #CCCCCC;
  fill:none;
}
g text{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #0F0F0F;
}
g .label{
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.x g line{
  color:#0F0F0F;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: calc(0.75em + 3.205rem);
  margin-bottom: 0px;
  background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e) !important;
}

.visRetailer {
  font-size: 1rem;
    font-weight: 600;
    line-height: 1.5;
    color: rgb(33, 37, 41)
}

#tooltip, #tooltip div {
  margin: 0px !important;
}

#tooltip {
  width: 200px;
}
.tooltip-barplot {
  position: absolute;
  margin:0px;
  z-index: 100000;
}

.tooltip-barplot div {
  margin:0px;
  background: white;
  border:1px solid #f8f8f8;
  padding: 10px;
}

.text_elipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tooltip { 
    pointer-events: none;
}

.graph-data-insights {
    padding: 15px 20px 15px 10px;
}
.graph-data-insights .dropdown-toggle {
    justify-content: space-between;
}
.graph-data-insights .dropdown-toggle span {
    font-family: 'Helvetica Neue';
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #0F0F0F;
    display: block;
    border-bottom: 1px solid #0F0F0F;
    padding-bottom: 3px;
}
.graph-line ul {
    margin: 60px 0 0 0;
    padding: 0;
}
.graph-line li {
    list-style: none;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color:  #0F0F0F;
}
.graph-data-insights .dropdown:hover .dropdown-menu {
  display: block;   
  right: 0;
    top: 50px;
}
.graph-data-acu .dropdown-tab-content .dropdown .show::after {
  content: none;
}

.graph-data-acu .two-select-boxes .inner-content-donut {
    padding: 30px 0 5px;
    text-align: center;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    margin: 12px 32px 0 0px;
    font-family: 'Helvetica Neue Light';
    color: #000000;
}
.graph-data-acu .graph-images {
    margin: 0px 0 12px;
}
.graph-data-acu .graph-images svg g text {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #0f0f0f;
    font-family: 'Helvetica Neue';
}
.graph-data-acu .graph-images svg g line {
  color: #0f0f0f;
}
.two-select-boxes {
  display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: center;
}
.two-select-boxes.dropdown-tab-content {
    justify-content: flex-end;
}
.graph-images .tooltip-barplot {
  margin: 0;
}

.graph-images .tooltip-barplot .graph-tooltip {
    background: #FFFFFF;
    box-shadow: 0px 12px 30px rgb(0 0 0 / 11%);
    border-radius: 4px;
    border: none;
    padding: 10px 17px;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
}

#adminsettings .react-table + .justify-content-center.align-items-center.row {
  margin-bottom: 50px;
}

.invalid-feedback{
  font-size: 12px !important;
}

.card{
  right:19px;
}

/* --------------------- 2-sept-2022 --------------------- */

#adminsettings .appbtn.button-with-bg {
  margin: 0px 0 10px;
  max-width: 250px;
}

#adminsettings form .card .text-primary {
color: #fff !important;
  background-color: #34c38f;
  border-color: #34c38f;
  padding: 8px 10px;
  border-radius: 4px;
  margin: 0 0 15px;
  display: block;
}


@media screen and (max-width: 1024px) and (orientation:landscape) {
  #sidebar-menu ul li a {
    padding: 9px 9px 9px 15px;
    display: flex;
    align-items: center;
  }
  #adminsettings form .card:last-child {
    box-shadow: none;
    padding: 0 8px;
  }
  #adminsettings form .card:last-child button {
    width: 100%;
  }
  #sidebar-menu ul.nav li a {
    padding: 9px 9px 9px 15px;
    display: flex;
    align-items: center;
  }
  .detailed-insights .dropdown-tab-content .dropdown {
    width: 200px;
    margin-right: 10px;
  }
  .detailed-insights .dropdown .show:after {
    content: none;
  }
}
@media (max-width: 767px) {
  #adminsettings form .card-body {
    padding: 0.5rem;
  }
  #adminsettings .appbtn.button-with-bg {
    max-width: 100%;
  }
  #adminsettings form .card:last-child {
      box-shadow: none;
  }
  .detailed-insights {
    margin: 0 25px;
  }
  .graph-data-acu .two-select-boxes.dropdown-tab-content {
    flex-wrap: wrap;
  }
  .graph-images .donutChart {
    width: 100% !important; 
  }
  .graph-data-acu .graph-images svg g text {
    font-size: 12px;
    line-height: 14px;
  }
  .detailed-insights .dropdown-tab-content .dropdown {
    width: 200px;
    margin-right: 10px;
  }
  .detailed-insights .dropdown-tab-content .reset-filter {
    margin-left: 0;
    margin-top: 0;
  }
  #adminsettings .card {
    box-shadow: none;
    right: 0;
  }
  #adminsettings .card-body {
    padding: 0.5rem 0;
  }
  .side-bar-menu .login-logo a, .login-logo a {
    font-size: 10px;
    line-height: 14px;
  }
  #adminsettings form .card:last-child .col-md-8.text-center {
    display: flex;
     align-items: center;
     padding: 0 20px;
  }
  #adminsettings button.btn-success {
    max-width: 150px;
  }
}

#adminsettings form .card:last-child .col-md-8.text-center {
  display: flex;
  align-items: center;
  margin: 0 0 0 15px;
}

#adminsettings form .card:last-child button, #adminsettings form .card:last-child .text-primary {
  width: 100%;
  max-width: 240px;
  margin: 0 0 10px 0;
}

#adminsettings button.btn-success.float-start {
  max-width: 250px;
}

.graph-images .visRetailer {
 font-size: 14px;
 line-height: 17px;
 color: #0F0F0F;
 font-weight: 400;
}
.graph-data-insights .dropdown-toggle:hover span {
  color: #3349FF;
  border-color: #3349FF;
}

#adminsettings form .card:last-child button, #adminsettings form .card:last-child .text-primary {
  width: 100%;
  max-width: 180px !important;
  margin: 0 0 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

#adminsettings form .card.mt-4:last-child {
  box-shadow: none;
}

#adminsettings form .card:last-child button, #adminsettings form .card:last-child .text-primary  {
  height: 45px;
}

.styled-radio {
  position: absolute;
  opacity: 0;
  z-index: 2;
  cursor: pointer;
  top: -5px;
  left: -5px;
  height: 60px;
  width: 250px;
}
.styled-radio + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.styled-radio + label:before {
  content: "";
  margin-right: 6px;
  display: inline-block;
  vertical-align: text-top;
  width: 16px;
  height: 16px;
  border: 1px solid rgba(188, 188, 188, 0.5);
  /*border-radius:0%;*/
}
.styled-radio:checked + label:after {
  content: "";
  position: absolute;
  left: 3px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}
.styled-radio:checked + label:before {
  background: var(--ac-secondary-black);
}

#adminsettings .inner-content {
  margin: 0px 0;
  padding: 0 15px;
  max-height: calc(100vh - 175px);
}

@media (max-width: 991px) {
  .two-select-boxes {
    flex-wrap: wrap;
  }
}

@media (max-width: 991px) {
  .graph-data-acu .graph-images svg g text {
    font-size: 10px;
    }
}

@media screen and (max-width: 767px) and (orientation:landscape) {
	#sidebar-menu {
	    height: 80vh;
	    overflow: auto;
	}
}
@media screen and (max-width: 576px) {
  #adminsettings .inner-content .col-sm-6.col-lg-7.col {
  width: 100%;
  flex: 0 0 100%;
  text-align: center;
  }
}

#heatmap .tooltipDesign ul p {
  width: 100% !important;
}

@media screen and (max-width: 767px) and (min-width: 577px) {
  #adminsettings .inner-content .col-sm-6.col-lg-7.col {
      width: 50%;
      flex: 0 0 50%;
  }
  #adminsettings .inner-content .col-sm-2.col-6.col-lg-2 {
    width: 25%;
  }
  }

.questions li label {
  cursor:pointer;
}
.form-control{
  border-radius: 0rem !important;
}
.btn-close{
  background: url(../images/mark.svg) no-repeat !important;
  opacity: unset !important;
  padding: 6px 6px 0px 0px !important;
}
.tab-content .inner-content {
	height: calc(100vh - 90px);
}
.tab-content .inner-content.settings {
	/*max-height: calc(100vh - 125px);*/
}
[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  width: 10px;
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
  background-size: 10px 10px;
}
 /* @media screen and (max-width: 1199px) and (min-width: 768px) {
	body:before {
		position: absolute;
		content: '';
		background: rgba(0,0,0,0.5);
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
	}
	body:after {
		position: absolute;
		content: 'Expand your window to view Acumen';
		width: 500px;
		height: 200px;
		background: #fff;
		left: 50%;
		right: 50%;
		text-align: center;
		z-index: 3;
		display: flex;
		top: 50%;
		font-size: 30px;
		transform: translate(-50%, -50%);
		box-shadow: 5px 5px 7px 2px #0000004f;
		align-items: center;
    font-size :16px;
    font-weight : 500;
    color :#000000;
    padding-left: 9%;
	}
}  */
.blurreffect:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999999;
  backdrop-filter: blur(100px);
  background: rgb(255 255 255 / 100%);
  font-family: 'Helvetica Neue';
}
.blurreffect:after {
  position: absolute;
  content: 'Expand your window to view Acumen';
  max-width: 500px;
  height: 200px;
  background: #fff;
  left: 50%;
  right: 50%;
  text-align: center;
  z-index: 999999;
  display: flex;
  top: 50%;
  transform: translate(-50%, -50%);
   /* box-shadow: 0px 30px 82px rgb(0 0 0 / 13%);  */
  align-items: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: #000000;
  font-family: 'Helvetica Neue Light';
  justify-content: center;
  width: 100%;
}
.blurreffect-mobile:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  backdrop-filter: blur(13px);
  background: rgb(255 255 255 / 85%);
  font-family: 'Helvetica Neue';
}
.blurreffect-mobile:after {
    position: absolute;
    content: 'Expand your window to view Acumen';
    width: 310px;
    height: 100px;
    background: #fff;
    left: 50%;
    right: 50%;
    text-align: center;
    z-index: 999;
    display: flex;
    top: 50%;
    transform: translate(-50%, -50%);
    /* box-shadow: 0px 30px 82px rgba(0, 0, 0, 0.13);; */
    align-items: center;
    font-size :16px;
    font-weight : 300;
    line-height: 20px;
    color :#000000;
    padding-left: 5px;
    font-family: 'Helvetica Neue Light';
}
.top-content-pf .dropdown .show::after {
  border-bottom: 15px solid #fff;
  top: -15px;
  }

/* @media screen and (max-width: 1199px) and (min-width: 768px) {
  body #layout-wrapper {
  -webkit-filter: blur(3px); 
      filter: blur(3px);
  }
} */

.right-bar-enabled #adminsettings .inner-content {
  max-height: calc(100vh - 175px);
}

.two-select-boxes .inner-content-donut.col-5{
  font-size: 12px !important;
  line-height: 14px !important;
  font-weight: 400;
  color: #0F0F0F !important;
  font-family:'Helvetica Neue';
}

.multi path.domain {
  stroke: currentColor; 
}

.styled-checkbox[disabled] + label:before {
  background-color: #808080;
}

.dropdown-tab-content .dropdown .dropdown-menu li.brandScroll {
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 8px;
  margin-bottom: 5px;
}
.dropdown-tab-content .dropdown .dropdown-menu li:last-child {
  padding: 10px 15px;
  position: relative;
}
.graph-line li span {
  min-width: 8px;
  max-width: 8px;
}
.tooltipDesign ul label {
  margin-bottom: 0;
  color: #6A6A6A;
}
.dropdown-tab-content .dropdown .dropdown-menu li.brandScroll:hover {
  background: transparent;
}
.dropdown-tab-content .dropdown .dropdown-menu li.brandScroll li:first-child:hover, .dropdown-tab-content .dropdown .dropdown-menu li.brandScroll li:last-child:hover {
  background: #F8F8F8 !important;
  cursor: pointer !important;
}
.dropdown-tab-content .dropdown:nth-child(2) .dropdown-menu li:first-child:hover {
  background: #F8F8F8 !important;
  cursor: pointer !important;
}

.not-subscribed-box h4 a {
  text-decoration: underline;
  text-decoration: underline !important;
  color: #0529E7;
}
.styled-checkbox[disabled] + label{
  color:#808080 !important;
}
/* .graph-data-insights .graph-line img {
  width: 100% !important;
  image-rendering: pixelated;
} */

.graph-label-wrapper, .graph-label-wrapper > div {
  display: flex;
  align-items: center;
}
.tick-label, .minus-label {
  width: 25%;
}
.tick-label svg, .minus-label svg {
min-width: 50px;
}
.tick-label p, .minus-label p {
font-size: 12px;
font-weight: 400;
color: #000000;
line-height: 14px;
}

.nobrandsSelect {
  font-size:  20px !important;
  color:  #000000 !important;
  line-height: 33px !important;
  font-style:  normal;
}

.graph-label-wrapper {
  justify-content: center;
}
.graph-label-wrapper .tick-label p, .graph-label-wrapper .minus-label p {
  margin-bottom:0;
}
.graph-data-acu .two-select-boxes .inner-content-donut h4 {
  font-size: 20px;
  line-height: 24px;
  max-width: 700px;
  -webkit-font-smoothing: antialiased;
}
/*.graph-data-acu .graph-images {
  display: flex;
  max-width: 70%;
  margin: 60px auto 30px;
  text-align: center;
}*/
.graph-images #barplotstack svg g rect {
  cursor: pointer;
}

.rrobjsidebar  ul  li {
  font-family: 'Helvetica Neue';
  font-size: 12px;
  line-height: 100%;
  text-align: left;
  color: #0F0F0F !important;
}


#ReturnObjectiveInsight svg g text.infotext {
    font-weight: 400 !important;
    font-size: 19px !important;
    line-height: 14px;
    font-family: 'Helvetica Neue';
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

#ReturnObjectiveInsight svg g text {
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 17px;
    font-family: 'Helvetica Neue';
    align-items: center;
    text-align: center;
    color: #0F0F0F;
}

.graph-data-acu .dropdown .dropdown-toggle{
  color:  #0F0F0F;
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}

.vizgraph h4 {
  font-family: 'Helvetica Neue Light';
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 19px;  
  margin-bottom: -9px;
}
.graph-data-acu h3:nth-child(1) {
  color: #0F0F0F;
}

.donutChart svg g g text {
 font-family: 'Helvetica Neue';
 font-style: normal;
 font-weight: 400;
 font-size: 14px !important;
 line-height: 17px; 
 align-items: center;
 text-align: right;
}

.tooltipDesign li span.color1-dot {
  max-width: 14px !important;
  margin-top: 0;
  max-height: 14px !important;
}

.tooltipDesign li.tooltip-barplot {
align-items: flex-start;
}

.tooltipDesign ul p {
  width: 66%;
}

.tooltip-barplot .tooltipDesign ul label {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #6A6A6A;
}
.graph-line li p {
  text-align: center;
  max-width: 83%;
  font-weight: 600;
}

.payment.graph-line li {
  justify-content: center;
}

.payment.graph-line li p {
  text-align: center;
  max-width: 83%;
  font-weight: 600 !important;
  margin-right: 10px;
  margin-bottom: 0px;
  margin-top: 30px;
  font-synthesis: none;
}

#barplotstack .tooltipDesign li span {
  max-width: 11px;
  max-height: 11px;
  margin-top: 0;
  border-radius: 2px;
  margin-top: 0px;
}

#barplotstack .tooltipDesign li {
align-items: flex-start;
}

#barplot .tooltipDesign li span {
  max-width: 11px;
  margin-top: 0px;
  border-radius: 2px;
}

#barplot .tooltipDesign li {
align-items: flex-start;
}

.unsubscriptiontitle {
  color: #000000 !important;
}

#viztitle {
  line-height: 19px !important;
}

#barplotH .tooltipDesign li span {
  max-width: 8px;
  max-height: 8px;
  border-radius: 2px;
  margin-top: 0px;
}

#barplotH .tooltipDesign li {
align-items: flex-start;
}

.dropdown-tab-content .dropdown .dropdown-menu li:first-child:hover {
    background: #F8F8F8;
    cursor: pointer;
}

@media screen and (max-width: 1440px) and (min-width: 1100px) {
	.two-select-boxes .inner-content-donut.col-5 {
		padding-right: 20px !important;
	}
	.two-select-boxes .dropdown.col-11 .dropdown-toggle {
		padding-right: 30px !important;
	}
}

#multibar > svg > g > g:nth-child(1) > path {
  stroke:  #000000;
  stroke-dasharray: 0;
  stroke-linecap: round;
  stroke-width: 1;
}

#multibar > svg > g > g.multi > path {
  stroke:  #000000;
}

.customergraph h4 {
  font-family: 'Helvetica Neue Light';
  font-style: normal;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 19px;  
  margin-bottom: -9px;
}
#custtitle {
  line-height: 24px !important;
  font-size: 20px !important;
}
@media screen and (max-width: 1600px) and (min-width: 1441px) {
	.two-select-boxes .dropdown.col-11 .dropdown-toggle {
		padding-right: 30px;
	}
}

#notification{
  cursor: pointer
}

.rt-td {
  white-space: normal !important
}

.tdTheme th {
  word-break: break-all;
}

.graph-data-acu .two-select-boxes #packagechart {
  padding: 0px 0 14px;
  text-align: center;
  font-weight: 400;
  font-size: 13.6288px;
  line-height: 16px;
  margin: 0;
  font-family: 'Helvetica Neue';
  color: #000000;
  padding-right: 30px;
}
.rr-y-axis-grid{
  stroke-width: 3.4375px;
  opacity: 1;
}

.graph-data-acu .two-select-boxes #smsnotifychart {
  padding: 3px 0 5px;
  text-align: center;
  font-weight: 400;
  font-size: 13.6288px;
  line-height: 16px;
  margin: 0;
  font-family: 'Helvetica Neue';
  color: #000000;
}
.min-graph-hieght{
  min-height: 500px;
}
.dropdown-tab-content .dropdown .dropdown-menu input[type="search"] {
  border-radius: 0px !important;
}
.offcanvas.notification-drawer .desc-info p{
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: #0F0F0F;
  margin-top: 5px;
}
.offcanvas.notification-drawer .offcanvas-body .date span{
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
}
.offcanvas.notification-drawer .time-info span{
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #878787;
  margin-top: 5px;

}
.offcanvas.notification-drawer .offcanvas-header h2{
  font-weight: 400;
}
.graph-data-acu #postpurchase svg .xaxis text {
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  color: #000000;
  font-family: 'Helvetica Neue';
}
.graph-data-acu #postpurchase svg .multi text {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  font-family: 'Helvetica Neue';
}
.graph-data-acu #postpurchase svg g line{
  stroke-width: 0.25px;
}
#postpurchase .multi path.domain{
  stroke-width: 0.25px;
}
/* admin side form start*/
.form-select{
  border-radius: 0px !important;
}
.form-select:active{
  outline: none !important;
  box-shadow: none !important;
  border-color: var(--ac-primary-green) !important;
}
.form-select:focus{
  outline: none !important;
  box-shadow: none !important;
  border-color: none !important;
}
.form-select.is-invalid:active{
  box-shadow: none !important;
  border-color: #f46a6a !important;
}
.form-control-admin:focus{
  outline: none !important;
  box-shadow: none !important;
  border-color: var(--ac-primary-green) !important;
}
.form-control-admin.is-invalid:focus{
  box-shadow: none !important;
  border-color: #f46a6a !important;
}
/* admin side form end*/

.icon-delete{
  margin-right: 10px;
}
.graph-data-acu .graph-images.payment svg g text{
  font-size: 16px;
}
.graph-data-acu .graph-images.payment svg .font13 text{
  font-size: 13px;
}
.graph-data-acu .graph-images.shipping svg g text{
  font-size: 15px !important;
}

.tooltip-barplot {
  visibility:hidden;
  opacity: 0;
  transition:visibility 0.2s linear,opacity 0.1s linear;
}

.graph-images #barplot svg g rect {
  cursor: pointer;
}

.graph-images #barplotH svg g rect {
  cursor: pointer;
}
.dropdown-tab-content .dropdown .dropdown-menu input[type="search"] {
  -webkit-appearance: unset;
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
.dropdown-tab-content .dropdown .dropdown-toggle {
  padding-right: 30px
}
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
	.two-select-boxes .inner-content-donut.col-5 {
		padding: 10px 5px 0 !important;
	}
	.two-select-boxes .questions.dropdown {
		width: 100%;
	}
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
	.graph-table .graph-table-content {
		margin-right: 0 !important;
    overflow-y: auto;
	}

	.graph-images div {
		margin: 0px 0px 30px 0px;
	}
	.graph-table table tbody th {
		padding: 15px;
	}
	.graph-table table td, .graph-table table th {
		padding: 15px 10px;
	}
	.two-select-boxes .dropdown {
		width: 47%;
	}
	.two-select-boxes.dropdown-tab-content {
		justify-content: space-between;
	}
	.graph-table table {
		table-layout: auto !important;
	}
	.graph-table table tbody th, .graph-table table tbody td {
		padding: 10px !important;
		min-width: 100px;
	}
	.graph-data-insights .graph-line.rrbrand {
		padding: 0;
	}
	.graph-data-acu h3 {
		font-size: 26px;
	}
}

@media screen and (max-width: 1330px) and (min-width: 1200px) {
	.graph-images .donutChart {
		width: 200px !important;
	}
}

@media screen and (min-width: 1200px) {
	.graph-data-acu .graph-images.donut {
		display: flex;
		justify-content: center;
    flex-wrap: wrap;
    margin-right: 27px;
    margin-left: -12px
	}
}

.paymenttick {
  pointer-events: none;
}
.graph-data{
  margin-bottom: 50px;
}
#heatmap rect{
  cursor: pointer;
}
.tooltipDesign{
  pointer-events: none;
}
.graph-data-acu .two-select-boxes .inner-content-donut.package h4 {
  font-size: 20px;
  line-height: 24px;
  max-width: 700px;
  margin-bottom: 15px;
  -webkit-font-smoothing: antialiased;
}
.graph-data-acu .two-select-boxes .content {
  padding: 5px 0 5px;
  text-align: center;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  margin: 0px 32px 0 0px;
  font-family: 'Helvetica Neue';
  color: #000000;
}
.graph-data-acu .two-select-boxes .inner-content-donut .smstitle{
  font-size: 20px;
  line-height: 24px;
  max-width: 700px;
  margin-bottom: -9px;
  -webkit-font-smoothing: antialiased;
}
@media screen and (max-width: 1366px) and (min-width: 1280px) {
  .author-img-details img {
      max-height: 300px;
      height: 300px;
  }
  .author-section .accordion .accordion-body {
    font-size: 16px;
    line-height: 26px;
  }
}
#page-header-notifications-dropdown span.green-dot {
  width: 8px;
  height: 8px;
  display: block;
  position: absolute;
  content: '';
  border-radius: 50%;
  top: 0;
  right: 10px;
  background: #4ACC86 !important;
  border : 1px solid #fff;
}
.dropdown-tab-content .questions.dropdown .dropdown-toggle{
  border: 1px solid #DEDEDE;
  border-radius: 1px;
  margin-bottom: 0;
  width: 240px;
  text-align: left;
  color: var(--ac-secondary-black);
  padding-right: 17px;
  max-width: 100%;
  font-size: 14px;
  font-weight: 500;
  z-index: 999;
}

.tab-wrap.tab-pane{
  position: relative;
  z-index: 1;
}

.graph-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.barplotstack {
  text-align: center;
}
.graph-wrapper-div ul {
  display: block;
  padding-left: 0;
}
.graph-wrapper-div ul li {
  display: inline-block;
  padding-left: 12px;
  font-size: 16px;
  position: relative;
  margin-right: 50px;
  color: #0F0F0F;
  line-height: 19px;
}
.graph-wrapper-div ul li:before {
  position: absolute;
  content: '';
  width: 8px;
  height: 8px;
  left: 0;
  background: red;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2px;
}
.graph-wrapper-div ul li:first-child:before {
  background: #1438F5;
}
.graph-wrapper-div ul li:nth-child(2):before {
  background: #4ACC86;
}
.graph-wrapper-div ul li:nth-child(3):before {
  background: #0f0f0f;
}
.graph-wrapper-div ul li:nth-child(4):before {
  background: #878787;
}
.graph-wrapper-div ul li:nth-child(5):before {
  background: rgba(188, 188, 188, 0.5); 
}
.graph-wrapper-div .graph-title h4 {
  font-size: 26px;
}
.graph-wrapper-div .graph-title h4 span {
  font-size: 18px;
  color: #0F0F0F;
}
.right-side-graph {
  height: 100%;
}
.graph-wrapper-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 100%;
}
.leader-selected-details1 h5{
  color: #0F0F0F;
  font-size: 28px;
  line-height: 33px;
  font-weight: 400;
  font-family: 'Helvetica Neue Light';
}
.leader-selected-details1 .content{
  color: #0F0F0F;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  opacity: 0.8;
}
@media screen and (min-width: 1366px) and (max-width: 1599px) {
  .graph-wrapper-div ul li {
    margin-right: 0;
    width: 50%; 
  }
}
.leader-content .leader-selected-details1 .row {
  align-items: flex-start;
}
@media screen and (min-width: 1600px) {
  .graph-wrapper-div ul li {
    margin-right: 0;
    width: 33.33% ;
    white-space: nowrap;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1365px) {
  .graph-wrapper-div ul li {
    margin-right: 0;
    width: 50%; 
  }
}
.form-check-input{
  padding: 0px;
  margin-right: 10px;
}
.form-check-label{
  margin-right: 30px;
}
input[type=color]{
  width: 15% !important;
  padding: 0.2rem;
}
input[type=color]:active{
  border-color: #ced4da;
}
input[type=color]:focus{
  border-color: #ced4da;
}
.card {
  margin-bottom: 30px !important;
  box-shadow: 0px 0rem 1rem 12px rgb(4 11 19 / 4%) !important;
}
.pr-page-title.setting {
  padding: 0px 56px 20px !important;
}
.insights-tabs :not(.active).nav-link:after {
  position: absolute;
  content: '';
  display: block;
  height: 1px;
  left: 0%;
  position: absolute;
  background: #000;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
  bottom: 0;
}

.insights-tabs .nav-link:hover:after {
  width: 100%;
  left: 0;
}
.leader-content .pulse-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: -43px 0 43px 0;
  align-items: center;
}

.pulse-dot {
  background: #4ACC86;
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	margin: 10px;
	height: 12px;
	width: 12px;
	transform: scale(1);
	animation: pulse-black 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
    border-radius: 100%;
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    border-radius: 100%;
  }
}

@keyframes pulse-black {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.3);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
@keyframes blink{
0%{opacity: 0;}
50%{opacity: .5;}
100%{opacity: 1;}
}

.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transition: all ease-in 0.5s !important;
  
}

.graph-data-acu .pulse-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: -43px 11px 43px 0;
  align-items: center;
}

.searchnew {
  width: 16px;
  margin: 28px 0 0 0;
}

.scroll-down {
  position: fixed;
  bottom: 0;
  background: #000;
  color: #fff;
  padding: 20px;
  border-radius: 20px;
  min-width: 250px;
  text-align: center;
  font-size: 16px;
  left: 50%;
  transform: translate(-50%,-50%);
  cursor: pointer;
}
.innovation{
  color: #0F0F0F;
  font-family: 'Helvetica Neue Light';
}

.editor .image {
  text-align: center;
}

.editor .image-style-side {
  text-align: right;
  float: right;
  max-width: 40%;
  margin-left: 1.5em;
}

figcaption {
  text-align: center;
}
.pointer  {
  cursor: pointer;
}
.pointer:hover{
  background-color: #F8F8F8;
}
.dropdown-menu li.show label.pointer {
  margin: 0px;
}
.editor p{
  color: #000000;
}
.tooltipDesign li span.tooltip_text{
  width:90px !important;
  height:auto !important;
  Margin:0px !important;
  max-width: 90px !important;
  max-height: inherit !important;
}

.dropdown-tab-content .dropdown .dropdown-toggle.multiselect {
  font-size: 0.8125rem;
  font-weight: 400;
  color: #49505f;
}