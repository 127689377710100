.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#0C9;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
	z-index: 100;
}
a.class, a.class:hover {
    text-decoration:underline;
    font-weight:bold;
    color:#FFF;
}

.my-float{
	margin-top:24.5px;
}
.multi-select{
    height: 120px;
}
.subInsave{
	width: 63px;

}

.subIndel{
	max-width: 120px;
}
.description {
	min-height: calc(1.5em + 3.94rem + 2px) !important;
}