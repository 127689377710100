.dropdown-flex{
    display: flex;
    flex-wrap: wrap;
}
.dropdown-tab-content {
    margin: 0px 0 10px;
    flex-wrap: wrap;
}
.PreviewDropdown{
    white-space: pre-wrap !important;
}
.but-pub #button-pre{
    margin-left: 62px;
    float: right;
    margin-top: -40px;
}
.dropdown-tab-content .dropdown .dropdown-toggle:focus {
    box-shadow: none;
    border: 1px solid #1438F5;
}