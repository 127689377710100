
/*Return Refund Details*/
.graph-table .graph-table-content {
  margin-right: 40px;
}
.graph-table table, .graph-table table tr, .graph-table table tbody, .graph-table table td, .graph-table table tfoot, .graph-table table th, .graph-table table thead, .graph-table .graph-table-content table tbody td, .graph-table .table>:not(:first-child) {
  border: 0 none;
} 
.graph-table .table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: #F9FAFC;
}
.graph-table table {
  text-align: center;
  vertical-align: middle;
  font-size: 11px;
  table-layout: fixed;
}
.graph-table table td, .graph-table table th {
  padding: 26px 20px;
  color: #000000 !important;
}
.graph-table table th {
  color: #000000 !important;
  text-align: center;
  font-weight: 400;
}
.graph-table table thead th {
  font-size: 11px;
  line-height: 13px;
}
.graph-table table tbody th {
  font-size: 12px;
  padding: 26px 30px;
  line-height: 14px;
}
.graph-table .green-badge span, .graph-table .red-badge span {
  border-radius: 30px;
  padding: 3px 10px;
}
.graph-table .green-badge span {
  background: #C3FFDB;
}
.graph-table .red-badge span {
  background: #FFCACA;
}
.rrbrand {
  padding: 6px;
}
.graph-data-insights .graph-line p {
  font-size: 12px;
  color: #0F0F0F;
}
.rrbrand h5{
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #0F0F0F;
  font-family: 'Helvetica Neue';
}
.graph-table table tbody td {
  line-height: 13px;
}
.graph-table .table-striped>tbody>tr th:first-child {
  --bs-table-accent-bg: transparent;
  line-height: 14px;
}
.returnrefund table tbody th {
  padding-left: 0px;
  padding-right: 20px;
}

.graph-table-content.returnrefund {
  margin-left: 0;
}
/*Return Refund Details*/