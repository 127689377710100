@font-face {
font-family: 'Helvetica Neue';
src: url('HelveticaNeue.eot'); /* IE9 Compat Modes */
src: url('HelveticaNeue.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('HelveticaNeue.woff') format('woff'), /* Modern Browsers */
     url('HelveticaNeue.ttf')  format('truetype'), /* Safari, Android, iOS */
     url('HelveticaNeue.svg#HelveticaNeue') format('svg') /* Legacy iOS */
}
@font-face {
font-family: 'helveticaneue-bold-webfont';
src: url('helveticaneue-bold-webfont.eot'); /* IE9 Compat Modes */
src: url('helveticaneue-bold-webfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('helveticaneue-bold-webfont.woff') format('woff'), /* Modern Browsers */
     url('helveticaneue-bold-webfont.ttf')  format('truetype'), /* Safari, Android, iOS */
     url('helveticaneue-bold-webfont.svg#HelveticaNeue') format('svg') /* Legacy iOS */
}
@font-face {
font-family: 'HelveticaNeue-Medium';
src: url('HelveticaNeue-Medium.eot'); /* IE9 Compat Modes */
src: url('HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('HelveticaNeue-Medium.woff') format('woff'), /* Modern Browsers */
     url('HelveticaNeue-Medium.ttf')  format('truetype'), /* Safari, Android, iOS */
     url('HelveticaNeue-Medium.svg#HelveticaNeue') format('svg') /* Legacy iOS */
}
@font-face {
font-family: 'HelveticaNeue-CondensedBold';
src: url('HelveticaNeue-CondensedBold.eot'); /* IE9 Compat Modes */
src: url('HelveticaNeue-CondensedBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('HelveticaNeue-CondensedBold.woff') format('woff'), /* Modern Browsers */
     url('HelveticaNeue-CondensedBold.ttf')  format('truetype'), /* Safari, Android, iOS */
     url('HelveticaNeue-CondensedBold.svg#HelveticaNeue') format('svg') /* Legacy iOS */
}
@font-face {
font-family: 'HelveticaNeueLTStd-Cn';
src: url('HelveticaNeueLTStd-Cn.eot'); /* IE9 Compat Modes */
src: url('HelveticaNeueLTStd-Cn.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('HelveticaNeueLTStd-Cn.woff') format('woff'), /* Modern Browsers */
     url('HelveticaNeueLTStd-Cn.ttf')  format('truetype'), /* Safari, Android, iOS */
     url('HelveticaNeueLTStd-Cn.svg#HelveticaNeue') format('svg') /* Legacy iOS */
}
@font-face {
font-family: 'HelveticaNeueLTStd-Roman';
src: url('HelveticaNeueLTStd-Roman.eot'); /* IE9 Compat Modes */
src: url('HelveticaNeueLTStd-Roman.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('HelveticaNeueLTStd-Roman.woff') format('woff'), /* Modern Browsers */
     url('HelveticaNeueLTStd-Roman.ttf')  format('truetype'), /* Safari, Android, iOS */
     url('HelveticaNeueLTStd-Roman.svg#HelveticaNeue') format('svg') /* Legacy iOS */
}
@font-face {
     font-family: 'Helvetica-ExtraLight';
     src: url('Helvetica-ExtraLight.eot'); /* IE9 Compat Modes */
     src: url('Helvetica-ExtraLight.woff') format('woff'), /* Modern Browsers */
          url('Helvetica-ExtraLight.ttf') format('truetype'), /* Safari, Android, iOS */
          url('Helvetica-ExtraLight.svg') format('svg'); /* Legacy iOS */
     font-weight: normal;
     font-style: normal;
}
@font-face {
     font-family: 'Helvetica Neue Light';
     src: url('HelveticaNeueLight.eot'); /* IE9 Compat Modes */
     src: url('HelveticaNeueLight.woff') format('woff'), /* Modern Browsers */
          url('HelveticaNeueLight.ttf') format('truetype'), /* Safari, Android, iOS */
          url('HelveticaNeueLight.svg') format('svg'); /* Legacy iOS */
     font-weight: normal;
     font-style: normal;
}
